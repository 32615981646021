
function setEvents (state, events) {
  // We don't need or want the events array to be reactive, since
  // it can be tens of thousands of items long.
  state.events = Object.freeze(events);
}

/** Selectively replace / insert / delete events
 * from state.events.
 *
 * modifiedEvents is the result of
 * /api/project/:project/event/changes?unique=t
 */
function setModifiedEvents (state, modifiedEvents) {
  const events = [...state.events];
  for (let evt of modifiedEvents) {
    const idx = events.findIndex(i => i.id == evt.id);
    if (idx != -1) {
      if (evt.is_deleted) {
        events.splice(idx, 1);
      } else {
        delete evt.is_deleted;
        events.splice(idx, 1, evt);
      }
    } else {
      if (!evt.is_deleted) {
        delete evt.is_deleted;
        events.unshift(evt);
      }
    }
  }
  setEvents(state, events);
}

function setEventsLoading (state, abortController = new AbortController()) {
  state.loading = abortController;
}

function clearEventsLoading (state) {
  state.loading = null;
}

function setEventsTimestamp (state, timestamp = new Date()) {
  if (timestamp === true) {
    const tstamp = state.events
      .map( event => event.modified_on )
      .reduce( (acc, cur) => acc > cur ? acc : cur );
    state.timestamp = tstamp ? new Date(tstamp) : new Date();
  } else {
    state.timestamp = timestamp;
  }
}

function setEventsETag (state, etag) {
  state.etag = etag;
}

function abortEventsLoading (state) {
  if (state.loading) {
    state.loading.abort();
  }
  state.loading = null;
}

export default {
  setEvents,
  setModifiedEvents,
  setEventsLoading,
  clearEventsLoading,
  abortEventsLoading,
  setEventsTimestamp,
  setEventsETag
};
