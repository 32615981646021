const _ = (s,k) =>
  k.split(".").reduce((a, b) => (a !== null && typeof a != "undefined") ? a[b] : a, s);

function serverEvent (state) {
  return state.serverEvent;
}

function online (state) {
  return !!_(state, "serverEvent.payload.new.meta._online");
}

function lineStatus (state) {
  return (_(state, "serverEvent.payload.new.meta.lineStatus")||"").trim();
}

function lineName (state) {
  return (_(state, "serverEvent.payload.new.meta.lineName")||"").trim();
}

function sequence (state) {
  const v = _(state, "serverEvent.payload.new.meta._sequence");
  return Number(v) || v;
}

function line (state) {
  const v = _(state, "serverEvent.payload.new.meta._line");
  return Number(v) || v;
}

function point (state) {
  const v = _(state, "serverEvent.payload.new.meta._point");
  return Number(v) || v;
}

function position (state) {
  const λ = Number(_(state, "serverEvent.payload.new.meta.longitude"));
  const φ = Number(_(state, "serverEvent.payload.new.meta.latitude"));

  if (!isNaN(λ) && !isNaN(φ)) {
    return [ λ, φ ];
  }
  return null;
}

function timestamp (state) {
  const v = _(state, "serverEvent.payload.new.meta.time");
  return v;
}

export default { serverEvent, online, lineName, sequence, line, point, position, timestamp };
