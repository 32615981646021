
function setServerEvent (state, serverEvent) {
  state.serverEvent = serverEvent;
}

function clearServerEvent (state) {
  state.serverEvent = null;
}

function setServerConnectionState (state, isConnected) {
  state.serverConnected = !!isConnected;
}

export default { setServerEvent, clearServerEvent, setServerConnectionState };
