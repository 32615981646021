import Vue from 'vue'
import Vuex from 'vuex'

import api from './modules/api'
import user from './modules/user'
import snack from './modules/snack'
import projects from './modules/projects'
import project from './modules/project'
import event from './modules/event'
import label from './modules/label'
import sequence from './modules/sequence'
import plan from './modules/plan'
import line from './modules/line'
import notify from './modules/notify'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api,
    user,
    snack,
    projects,
    project,
    event,
    label,
    sequence,
    plan,
    line,
    notify
  }
})
