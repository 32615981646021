
function setLines (state, lines) {
  // We don't need or want the events array to be reactive, since
  // it can be tens of thousands of items long.
  state.lines = Object.freeze(lines);
}

function setLinesLoading (state, abortController = new AbortController()) {
  state.loading = abortController;
}

// This assumes that we know any transactions have finished or we
// don't care about aborting.
function clearLinesLoading (state) {
  state.loading = null;
}

function setLinesTimestamp (state, timestamp = new Date()) {
  // NOTE: There is no `modified_on` property in the lines
  // result or in the database schema, but we could perhaps add
  // one.
  if (timestamp === true) {
    const tstamp = state.lines
      .map( event => event.modified_on )
      .reduce( (acc, cur) => acc > cur ? acc : cur );
    state.timestamp = tstamp ? new Date(tstamp) : new Date();
  } else {
    state.timestamp = timestamp;
  }
}

function setLinesETag (state, etag) {
  state.etag = etag;
}

function abortLinesLoading (state) {
  if (state.loading) {
    state.loading.abort();
  }
  state.loading = null;
}

export default {
  setLines,
  setLinesLoading,
  clearLinesLoading,
  setLinesTimestamp,
  setLinesETag
};
