
/** Fetch projects from server
 */
async function refreshProjects ({commit, dispatch, state, rootState}) {

  if (state.loading) {
    commit('abortProjectsLoading');
  }

  commit('setProjectsLoading');
  const pid = rootState.project.projectId;
  const url = `/project`;
  const init = {
    signal: state.loading.signal
  };
  const res = await dispatch('api', [url, init]);

  if (res) {
    commit('setProjects', res);
    commit('setProjectsTimestamp');
  }
  commit('clearProjectsLoading');
}

/** Return a subset of projects from state.projects
 */
async function getProjects ({commit, dispatch, state}, [{pid, name, schema, group, sortBy, sortDesc, itemsPerPage, page, text}] = [{}]) {
  let filteredProjects = [...state.projects];

  if (sortBy) {

    sortBy.forEach( (key, idx) => {
      filteredProjects.sort( (el0, el1) => {
        const a = el0?.[key];
        const b = el1?.[key];
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else if (a == b) {
          return 0;
        } else if (a && !b) {
          return 1;
        } else if (!a && b) {
          return -1;
        } else {
          return 0;
        }
      });
      if (sortDesc && sortDesc[idx] === true) {
        filteredProjects.reverse();
      }
    });

  }

  if (pid) {
    filteredProjects = filteredProjects.filter( project => project.pid == pid );
  }

  if (name) {
    filteredProjects = filteredProjects.filter( project => project.name.toLowerCase().includes(name.toLowerCase()) );
  }

  if (schema) {
    filteredProjects = filteredProjects.filter( project => project.schema.toLowerCase().includes(schema.toLowerCase()) );
  }

  if (group) {
    filteredProjects = filteredProjects.filter( project => project.groups.find(g => g.toLowerCase() == group.toLowerCase()) );
  }

  if (text) {
    const tstampFilter = (value, search, item) => {
      return search?.length >= 5 && textFilter(value, search, item);
    };

    const numberFilter = (value, search, item) => {
      return value == search;
    };

    const textFilter = (value, search, item) => {
      return String(value).toLowerCase().includes(search.toLowerCase());
    };

    const arrayFilter = (elementFilter) => {
      return (value, search, item) => value.some(element => elementFilter(element, search, item));
    };

    const searchFunctions = {
      pid: textFilter,
      name: textFilter,
      group: arrayFilter(textFilter)
    };

    filteredProjects = filteredProjects.filter ( project => {
      for (let key in searchFunctions) {
        const fn = searchFunctions[key];
        if (fn(project[key], text, project)) {
          return true;
        }
      }
      return false;
    });
  }

  const count = filteredProjects.length;

  if (itemsPerPage && itemsPerPage > 0) {
    const offset = (page > 0)
      ? (page-1) * itemsPerPage
      : 0;

    filteredProjects = filteredProjects.slice(offset, offset+itemsPerPage);
  }

  return {projects: filteredProjects, count};
}

export default { refreshProjects, getProjects };
