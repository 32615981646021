<template>
  <v-card>
    <v-card-title>Planner settings</v-card-title>
    <v-card-subtitle>Default values when creating new sequences in the planner. These values can then be changed for each individual entry.</v-card-subtitle>
    <v-card-text>
      <v-form>
        <v-text-field
          label="Default line change duration"
          suffix="min"
          type="number"
          hint="Expected line change time in minutes"
          persistent-hint
          v-model.number="cwo.defaultLineChangeDuration"
        >
        </v-text-field>
        <v-text-field
          label="Default acquisition speed"
          suffix="kt"
          type="number"
          hint="Expected acquisition speed in knots"
          persistent-hint
          v-model.number="cwo.defaultAcquisitionSpeed"
        >
        </v-text-field>
        <v-text-field
          label="Overlap before"
          type="number"
          hint="Default number of shots to overlap before the FGSP, for reshoots"
          persistent-hint
          v-model.number="cwo.overlapBefore"
        >
        </v-text-field>
        <v-text-field
          label="Overlap after"
          type="number"
          hint="Default number of shots to overlap after the LGSP, for reshoots"
          persistent-hint
          v-model.number="cwo.overlapAfter"
        >
        </v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!--
      <v-btn
        color="primary"
        @click="save"
      >Save</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="warning"
        @click="reset"
      >Reset</v-btn>
      -->
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="back"
      >Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { deepSet } from '@/lib/utils';

export default {
  name: "DougalProjectSettingsPlanner",

  props: {
    value: Object
  },

  data () {
    return {
    }
  },

  computed: {
    // Current working object.
    // A shortcut so we don't have to specify the full path
    // on every input control. It also makes it easier to
    // change that path if necessary. Finally, it ensures that
    // the properties being modified are always available.
    cwo: {

      get () {
        if (this.value) {
          if (!this.value?.planner) {
            deepSet(this.value, [ "planner" ], {
              defaultLineChangeDuration: null,
              defaultAcquisitionSpeed: null,
              overlapBefore: null,
              overlapAfter: null
            });
          }
          return this.value.planner;
        } else {
          return {};
        }
      },

      set (v) {
        if (this.value) {
          deepSet(this.value, [ "planner" ], v);
        }
      }

    }
  },


  methods: {

    reset () {
    },

    save () {
    },

    back () {
      this.$emit('close');
    }

  },

  mounted () {
    this.reset();
  }

}
</script>
