<template>
  <v-card>
    <v-card-title>Name &amp; ID</v-card-title>
    <v-card-subtitle>The survey's name and short ID. The latter must match the value used in ASAQC, if applicable.</v-card-subtitle>
    <v-card-text>
      <v-form>
        <v-text-field
          label="ID"
          hint="Short survey ID"
          persistent-hint
          v-model="cwo.id"
        >
        </v-text-field>
        <v-text-field
          label="Name"
          hint="Survey name"
          persistent-hint
          v-model="cwo.name"
        >
        </v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!--
      <v-btn
        color="primary"
        @click="save"
      >Save</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="warning"
        @click="reset"
      >Reset</v-btn>
      -->
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="back"
      >Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "DougalProjectSettingsNameId",

  props: {
    value: Object
  },

  data () {
    return {
    }
  },

  computed: {
    // Current working object.
    // A shortcut so we don't have to specify the full path
    // on every input control. It also makes it easier to
    // change that path if necessary. Finally, it ensures that
    // the properties being modified are always available.
    cwo: {

      get () {
        if (this.value) {
          return this.value;
        } else {
          return {};
        }
      },

      set (v) {
        this.value = v;
      }

    }
  },

  methods: {

    reset () {
    },

    save () {
    },

    back () {
      this.$emit('close');
    }

  },

  mounted () {
    this.reset();
  }

}
</script>
