<template>
  <v-row dense no-gutters>

    <v-col cols="1">
      <slot name="prepend"></slot>
    </v-col>

    <v-col cols="2">
      <v-chip outlined label small :color="colour">{{name}}</v-chip>
    </v-col>

    <v-col cols="2">
      <v-text-field
        class="ml-3"
        dense
        label="From"
        type="number"
        min="0"
        v-model.number="value.offset"
        :readonly="readonly"
      ></v-text-field>
    </v-col>

    <v-col cols="2">
      <v-text-field
        class="ml-3"
        dense
        label="Length"
        type="number"
        min="0"
        v-model.number="value.length"
        :readonly="readonly"
      ></v-text-field>
    </v-col>

    <v-col cols="2">
      <dougal-field-content-dialog
        :readonly="readonly"
        :value="value"
        @input="$emit('input', $event)"
      ></dougal-field-content-dialog>

    </v-col>

    <v-col cols="1">
      <slot name="append"></slot>
    </v-col>

  </v-row>

</template>

<style scoped>

.input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  min-height: 32px;
  max-height: 32px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
}

.input >>> .chunk {
  padding-inline: 1px;
  border: 1px solid;
}

.input >>> .chunk-empty {
  padding-inline: 1px;
}

.input >>> .chunk-overlap {
  padding-inline: 1px;
  border: 1px solid grey;
  color: grey;
}
</style>

<script>
import DougalFieldContentDialog from '../fields/field-content-dialog'

export default {
  name: "DougalFixedStringDecoderField",

  components: {
    DougalFieldContentDialog
  },

  props: {
    value: Object,
    name: String,
    colour: String,
    readonly: Boolean,
  },

  data () {
    return {
      name_: "",
    }
  },

  computed: {
  },

  watch: {

    name () {
      if (this.name != this.name_) {
        this.name_ = this.name;
      }
    },
  },

  methods: {

    addField () {
      if (!this.fieldNameErrors) {
        this.$emit("update:fields", {
          ...this.fields,
          [this.fieldName]: { offset: 0, length: 0 }
        });
        this.fieldName = "";
      }
    },

    reset () {
      this.text_ = this.text;
    }

  },

  mounted () {
    this.reset();
  }

}
</script>
