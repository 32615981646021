
function queueRequest (state) {
  state.requestsCount++;
}

function dequeueRequest (state) {
  if (state.requestsCount > 0) {
    state.requestsCount--;
  }
}

export default { queueRequest, dequeueRequest };
