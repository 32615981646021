<template>
  <v-card>
    <v-card-title>Pending sequence detection</v-card-title>
    <v-card-subtitle>Sequences which are pending acceptance (e.g., due to marginal quality) can be marked as such by the naming of their <b>final</b> files or parent directory. Dougal uses regular expression matching against the full path, not just the file name. This pattern applies to both P1/90 and P1/11 files.</v-card-subtitle>
    <v-card-text>
      <v-form>
        <v-text-field
          label="Pattern"
          hint="Regular expression text"
          persistent-hint
          v-model="cwo.pattern.regex"
        >
        </v-text-field>
        <v-text-field
          label="Flags"
          hint="Regular expression modifier flags"
          persistent-hint
          v-model="cwo.pattern.flags"
        >
        </v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!--
      <v-btn
        color="primary"
        @click="save"
      >Save</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="warning"
        @click="reset"
      >Reset</v-btn>
      -->
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="back"
      >Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { deepSet } from '@/lib/utils';

export default {
  name: "DougalProjectSettingsFinalPending",

  props: {
    value: Object
  },

  data () {
    return {
    }
  },

  computed: {
    // Current working object.
    // A shortcut so we don't have to specify the full path
    // on every input control. It also makes it easier to
    // change that path if necessary. Finally, it ensures that
    // the properties being modified are always available.
    cwo: {

      get () {
        if (this.value) {
          if (!this.value?.final?.pending.pattern) {
            deepSet(this.value, [ "final", "pending", "pattern" ], {
              flags: "i",
              regex: "PENDING"
            });
          }
          return this.value.final.pending;
        } else {
          return {
            flags: "i",
            regex: "PENDING"
          };
        }
      },

      set (v) {
        if (this.value) {
          deepSet(this.value, [ "final", "pending", "pattern" ], v);
        }
      }

    }
  },

  watch: {
  },

  methods: {

    reset () {
    },

    save () {
    },

    back () {
      this.$emit('close');
    }

  },

  mounted () {
    this.reset();
  }

}
</script>
