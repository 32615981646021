/** Return an HSL colour as a function of an input value
  * `str`.
  *
  * Consider using as getHSL.bind(this) in Vue components
  * in order to get access to the Vuetify theme configuration.
  */
function getHSL (str, saturation = 1, lightness = 0.25, offset = 0) {

  function getHash (v) {
    if (typeof (v??false)[Symbol.iterator] != "function") {
      // Not an iterable, make it one
      v = String(v);
    }

    return Math.abs([...v, ..." "].reduce( (acc, cur) => String(cur).charCodeAt(0) + ((acc << 5) - acc), 0 ));
  }

  const h = (getHash(str) + offset) % 360;
  const s = saturation * 100;
  const l = this?.$vuetify?.theme?.isDark
    ? (1-lightness) * 100
    : lightness * 100;

  return {h, s, l};

}

/** Return a CSS hsl() or hsla() colour
 * representation as a function of an input value.
 *
 * Consider using as getHSLColourFor.bind(this) – See
 * note for getHSL() above.
 */
function getHSLColourFor (str, opacity = 1, saturation, lightness, offset) {
  const _getHSL = getHSL.bind(this);
  const {h, s, l} = _getHSL(str, saturation, lightness, offset);
  if (opacity == 1) {
    return `hsl(${h},${s}%,${l}%)`;
  } else {
    return `hsla(${h},${s}%,${l}%, ${opacity})`;
  }
}

export {
  getHSL,
  getHSLColourFor
}
