<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-form :disabled="loading">
          <v-card class="mx-auto" max-width="600" tile>
            <v-card-title style="word-break: normal;">Login</v-card-title>
            <v-card-text>

              <v-text-field
                v-model="credentials.user"
                label="User"
                required
              >
              </v-text-field>
              <v-text-field
                v-model="credentials.password"
                type="password"
                label="Password"
                required
              >
              </v-text-field>


            </v-card-text>
            <v-card-actions>

              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="submit"
              >Login
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                class="mr-4"
                @click="reset"
              >Reset
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Login',

  components: {
  },

  data () {
    return {
      credentials: {
        user: null,
        password: null
      }
    };
  },

  computed: {
    valid () {
      return this.credentials.user !== null && this.credentials.password !== null;
    },

    ...mapGetters(['loading', 'user'])
  },

  methods: {

    async submit () {

      await this.logout();
      await this.login(this.credentials);

      if (this.user && !this.user.autologin) {
        this.$router.replace("/");
      } else {
        this.showSnack(["Bad login", "warning"]);
      }
    },

    reset () {
      this.credentials = {user: null, password: null};
    },

    ...mapActions(["login", "logout", "showSnack"])
  }
}
</script>
