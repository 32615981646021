<template>
  <v-container fluid fill-height class="ma-0 pa-0">
    <v-row no-gutters align="stretch" class="fill-height">
      <v-col cols="12" v-if="projectFound">
        <!-- Show component here according to selected route -->
        <keep-alive>
          <router-view :key="$route.path"></router-view>
        </keep-alive>
      </v-col>
      <v-col cols="12" v-else>
        <v-card>
          <v-card-text>
            Project does not exist.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Project',
  components: {
  },
  data () {
    return {
    //
    }
  },

  computed: {

    projectFound () {
      return this.loading || this.projectId;
    },

    ...mapGetters(["loading", "projectId", "projectSchema", "serverEvent"])
  },

  watch: {
    async serverEvent (event) {
      if (event.channel == "project" && event.payload?.operation == "DELETE" && event.payload?.schema == "public") {
        // Project potentially deleted
        await this.getProject(this.$route.params.project);
      } else if (event.payload?.schema == this.projectSchema) {
        if (event.channel == "event") {
          this.refreshEvents();
        } else if (event.channel == "planned_lines") {
          this.refreshPlan();
        } else if (["raw_lines", "raw_shots", "final_lines", "final_shots"].includes(event.channel)) {
          this.refreshSequences();
        } else if (["preplot_lines", "preplot_points"].includes(event.channel)) {
          this.refreshLines();
        } else if (event.channel == "info") {
          if ((event.payload?.new ?? event.payload?.old)?.key == "plan") {
            this.refreshPlan();
          }
        } else if (event.channel == "project") {
          this.getProject(this.$route.params.project);
        }
      }
    }
  },

  methods: {
    ...mapActions(["getProject", "refreshLines", "refreshSequences", "refreshEvents", "refreshLabels", "refreshPlan"])
  },

  async mounted () {
    await this.getProject(this.$route.params.project);
    if (this.projectFound) {
      this.refreshLines();
      this.refreshSequences();
      this.refreshEvents();
      this.refreshLabels();
      this.refreshPlan();
    }
  }

}
</script>
