import { deepCompare } from './utils';

function setIfDifferent(propsLocals) {
  return Object.fromEntries(Object.entries(propsLocals).map( ([prop, local]) => [
    local,
    () => {
      if (!deepCompare(this[prop], this[local])){
        this[local] = structuredClone(this[prop]);
      }
    }
  ]));
}

export default setIfDifferent;
