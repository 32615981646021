
function labels (state) {
  return state.labels;
}

/** Return labels that can be added by users.
 *
 * As opposed to system labels.
 */
function userLabels (state) {
  return Object.fromEntries(Object.entries(state.labels).filter(i => i[1].model.user));
}

function labelCount (state) {
  return state.labels?.length ?? 0;
}

function labelsLoading (state) {
  return !!state.loading;
}

export default { labels, userLabels, labelCount, labelsLoading };
