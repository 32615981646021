<template>
  <v-card>
    <v-card-title>Name, ID &amp; geodetics</v-card-title>
    <v-card-subtitle>The survey's name, short ID and EPSG code. This is the minimum required to create a new survey. <b>These values cannot be changed once the project has been created!</b></v-card-subtitle>
    <v-card-text>
      <v-form>
        <v-text-field
          label="ID"
          hint="Short survey ID"
          persistent-hint
          v-model="id"
        >
        </v-text-field>
        <v-text-field
          label="Name"
          hint="Survey name"
          persistent-hint
          v-model="name"
        >
        </v-text-field>
        <v-text-field
          label="EPSG code"
          hint="EPSG code of the project's coordinate reference system"
          persistent-hint
          type="number"
          v-model.number="epsg"
        >
        </v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        @click="save"
      >Save</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="warning"
        @click="reset"
      >Reset</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="back"
      >Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "DougalProjectSettingsNameIdGeodetics",

  props: [ "value" ],

  data () {
    return {
      id: "",
      name: "",
      epsg: null
    }
  },

  watch: {
    value (newValue) {
      this.reset();
    }
  },

  methods: {

    reset () {
      for (const key of Object.keys(this.$data)) {
        this[key] = this.value[key];
      }
    },

    save () {
      this.$emit('input', {...this.$data});
    },

    back () {
      this.$emit('close');
    }

  },

  mounted () {
    this.reset();
  }

}
</script>
