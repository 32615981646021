<template>
  <v-card>
    <v-card-title>Not implemented</v-card-title>
    <v-card-text>
      The code for this configuration section has not yet been implemented.
    </v-card-text>
    <v-card-actions>
      <v-btn
        disabled
        color="primary"
        @click="save"
      >Save</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        disabled
        color="warning"
        @click="reset"
      >Reset</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="back"
      >Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "DougalProjectSettingsNotImplemented",

  props: [ "value" ],

  data () {
    return {
    }
  },

  watch: {
    value (newValue) {
      this.reset();
    }
  },

  methods: {

    reset () {
    },

    save () {
    },

    back () {
      this.$emit('close');
    }

  },

  mounted () {
    this.reset();
  }

}
</script>
