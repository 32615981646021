<template>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Logout',

  methods: {
    ...mapActions(["logout"])
  },

  async created () {
    await this.logout();
    this.$router.replace("/");
  }
}
</script>
