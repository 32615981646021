<template>
<div class="line-status" v-if="sequences.length == 0">
  <slot name="empty"></slot>
</div>
<div class="line-status" v-else-if="sequenceHref || plannedSequenceHref || pendingReshootHref">
  <router-link v-for="sequence in sequences" :key="sequence.sequence" v-if="sequenceHref"
    class="sequence"
    :class="sequence.status"
    :style="style(sequence)"
    :title="title(sequence)"
    :to="sequenceHref(sequence)"
  >
  </router-link>
  <router-link v-for="sequence in plannedSequences" :key="sequence.sequence" v-if="plannedSequenceHref"
    class="sequence planned"
    :style="style(sequence)"
    :title="title(sequence, 'planned')"
    :to="plannedSequenceHref(sequence)"
  >
  </router-link>
  <router-link v-for="(line, key) in pendingReshoots" :key="key" v-if="pendingReshootHref"
    class="sequence reshoot"
    :style="style(line)"
    :title="title(line, 'reshoot')"
    :to="pendingReshootHref(line)"
  >
  </router-link>
</div>
<div class="line-status" v-else>
  <div v-for="sequence in sequences" :key="sequence.sequence"
    class="sequence"
    :class="sequence.status"
    :style="style(sequence)"
    :title="title(sequence)"
  >
  </div>
  <div v-for="sequence in plannedSequences" :key="sequence.sequence"
    class="sequence planned"
    :style="style(sequence)"
    :title="title(sequence, 'planned')"
  >
  </div>
  <div v-for="(line, key) in pendingReshoots" :key="key"
    class="sequence reshoot"
    :style="style(line)"
    :title="title(line, 'reshoot')"
  >
  </div>
</div>
</template>

<style lang="stylus" scoped>
.line-status
  display flex
  flex-direction column
  height 67%
  min-width 64px
  min-height 16px
  background-color #d3d3d314
  border-radius 4px

  .sequence
    flex 1 1 auto
    opacity 0.5
    border-radius 4px

    &.ntbp
      background-color red
    &.raw
      background-color orange
    &.final
      background-color green
    &.online
      background-color blue
    &.planned
      background-color magenta
    &.reshoot
      background repeating-linear-gradient(-45deg, rgba(255,0,255,0.302), brown 5px, rgba(247, 247, 247, 0.1) 5px, rgba(242, 241, 241, 0.08) 10px), repeating-linear-gradient(45deg, rgba(255,0,255,0.302), brown 5px, rgba(247, 247, 247, 0.1) 5px, rgba(242, 241, 241, 0.08) 10px)
</style>

<script>

export default {
  name: 'DougalLineStatus',

  props: {
    preplot: Object,
    sequences: Array,
    "sequence-href": Function,
    "planned-sequences": Array,
    "planned-sequence-href": Function,
    "pending-reshoots": Array,
    "pending-reshoot-href": Function
  },

  methods: {
    style (s) {
      const values = {};
      const fsp = s.status == "final"
        ? s.fsp_final
        : s.status == "ntbp"
          ? (s.fsp_final || s.fsp)
          : s.fsp; /* status == "raw" or planned sequence or pending reshoot */

      const lsp = s.status == "final"
        ? s.lsp_final
        : s.status == "ntbp"
          ? (s.lsp_final || s.lsp)
          : s.lsp; /* status == "raw" or planned sequence or pending reshoot */

      const pp0 = Math.min(this.preplot.fsp, this.preplot.lsp);
      const pp1 = Math.max(this.preplot.fsp, this.preplot.lsp);
      const len = pp1-pp0;
      const sp0 = Math.max(Math.min(fsp, lsp), pp0);
      const sp1 = Math.min(Math.max(fsp, lsp), pp1);

      const left = (sp0-pp0)/len;
      const right = 1-((sp1-pp0)/len);

      values["margin-left"] = left*100 + "%";
      values["margin-right"] = right*100 + "%";

      return values;
    },

    title (s, type) {
      if (s.status || type == "planned") {
        const status = s.status == "final"
          ? "Final"
          : s.status == "raw"
            ? "Acquired"
            : s.status == "ntbp"
              ? "NTBP"
              : type == "planned"
                ? "Planned"
                : s.status;

        const remarks = "\n"+[s.remarks, s.remarks_final].join("\n").trim()

        return `Sequence ${s.sequence} – ${status} (${s.fsp_final || s.fsp}−${s.lsp_final || s.lsp})${remarks}`;
      } else if (type == "reshoot") {
        return `Pending reshoot (${s.fsp}‒${s.lsp})${s.remarks? "\n"+s.remarks : ""}`;
      }
    }
  }

}

</script>
