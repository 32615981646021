
function projects (state) {
  return state.projects;
}

function projectGroups (state) {
  return [...new Set(state.projects.map(i => i.groups).flat())].sort();
}

function projectCount (state) {
  return state.projects?.length ?? 0;
}

function projectsLoading (state) {
  return !!state.loading;
}

export default { projects, projectGroups, projectCount, projectsLoading };
