
async function getProject ({commit, dispatch}, projectId) {
  const init = {
    headers: {
      "If-None-Match": "" // Ensure we get a fresh response
    }
  };
  const res = await dispatch('api', [`/project/${String(projectId).toLowerCase()}/configuration`, init]);
  if (res) {
    commit('setProjectName', res.name);
    commit('setProjectId', res.id?.toLowerCase());
    commit('setProjectSchema', res.schema);
    commit('setProjectConfiguration', res);
    const recentProjects = JSON.parse(localStorage.getItem("recentProjects") || "[]")
    recentProjects.unshift(res);
    localStorage.setItem("recentProjects", JSON.stringify(recentProjects.slice(0, 3)));
  } else {
    commit('setProjectName', null);
    commit('setProjectId', null);
    commit('setProjectSchema', null);
    commit('setProjectConfiguration', {});
  }
}

export default { getProject };
