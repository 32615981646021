<template>
  <v-card>
    <v-card-title>Binning parameters</v-card-title>
    <!-- <v-card-subtitle></v-card-subtitle> -->
    <v-card-text>
      <v-form>
        <v-text-field
          label="Azimuth"
          hint="Direction of I (inline) axis"
          persistent-hint
          suffix="°"
          type="number"
          v-model.number="cwo.theta"
        >
        </v-text-field>
        <v-text-field
          label="I increment"
          hint="Bin number increment along the inline axis"
          persistent-hint
          type="number"
          min="0"
          step="1"
          v-model.number="cwo.I_inc"
        >
        </v-text-field>
        <v-text-field
          label="J increment"
          hint="Bin number increment along the crossline axis"
          persistent-hint
          type="number"
          min="0"
          step="1"
          v-model.number="cwo.J_inc"
        >
        </v-text-field>
        <v-text-field
          label="I width"
          hint="Inline bin width (can be negative)"
          persistent-hint
          type="number"
          v-model.number="cwo.I_width"
        >
        </v-text-field>
        <v-text-field
          label="J width"
          hint="Crossline bin width (can be negative)"
          persistent-hint
          type="number"
          v-model.number="cwo.J_width"
        >
        </v-text-field>
        <fieldset class="pa-3 mt-3">
          <legend>Origin</legend>
          <v-row>
            <v-col>
              <v-text-field
                label="Easting"
                hint="Bin origin easting"
                persistent-hint
                type="number"
                v-model.number="cwo.origin.easting"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Northing"
                hint="Bin origin northing"
                persistent-hint
                type="number"
                v-model.number="cwo.origin.northing"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="I"
                hint="Bin origin inline"
                persistent-hint
                type="number"
                step="1"
                v-model.number="cwo.origin.I"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="J"
                hint="Bin origin crossline"
                persistent-hint
                type="number"
                step="1"
                v-model.number="cwo.origin.J"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </fieldset>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!--
      <v-btn
        color="primary"
        @click="save"
      >Save</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="warning"
        @click="reset"
      >Reset</v-btn>
      -->
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="back"
      >Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { deepSet } from '@/lib/utils';

export default {
  name: "DougalProjectSettingsBinning",

  props: {
    value: Object
  },

  data () {
    return {
    }
  },

  computed: {
    // Current working object.
    // A shortcut so we don't have to specify the full path
    // on every input control. It also makes it easier to
    // change that path if necessary. Finally, it ensures that
    // the properties being modified are always available.
    cwo: {

      get () {
        if (this.value) {
          if (!this.value?.binning) {
            deepSet(this.value, [ "binning" ], {
              theta: null,
              Iinc: 1,
              Jinc: 1,
              Iwidth: null,
              Jwidth: null,
              origin: {
                easting: null,
                northing: null,
                I: null,
                J: null
              }
            });
          }
          return this.value.binning;
        } else {
          return {};
        }
      },

      set (v) {
        if (this.value) {
          deepSet(this.value, [ "binning" ], v);
        }
      }

    }
  },

  methods: {

    reset () {
    },

    save () {
    },

    back () {
      this.$emit('close');
    }
  },

  mounted () {
    this.reset();
  }
}
</script>
