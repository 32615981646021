<template>
  <div>
  LineSummary
  </div>
</template>

<script>

export default {
  name: "LineSummary"
}

</script>
