
function setSequences (state, sequences) {
  // We don't need or want the events array to be reactive, since
  // it can be tens of thousands of items long.
  state.sequences = Object.freeze(sequences);
}

function setSequencesLoading (state, abortController = new AbortController()) {
  state.loading = abortController;
}

// This assumes that we know any transactions have finished or we
// don't care about aborting.
function clearSequencesLoading (state) {
  state.loading = null;
}

function setSequencesTimestamp (state, timestamp = new Date()) {
  // NOTE: There is no `modified_on` property in the sequences
  // result or in the database schema, but we should probably add
  // one.
  if (timestamp === true) {
    const tstamp = state.sequences
      .map( event => event.modified_on )
      .reduce( (acc, cur) => acc > cur ? acc : cur );
    state.timestamp = tstamp ? new Date(tstamp) : new Date();
  } else {
    state.timestamp = timestamp;
  }
}

function setSequencesETag (state, etag) {
  state.etag = etag;
}

function abortSequencesLoading (state) {
  if (state.loading) {
    state.loading.abort();
  }
  state.loading = null;
}

export default {
  setSequences,
  setSequencesLoading,
  clearSequencesLoading,
  setSequencesTimestamp,
  setSequencesETag
};
