
function setProjects (state, projects) {
  // We don't need or want the array to be reactive
  state.projects = Object.freeze(projects);
}

function setProjectsLoading (state, abortController = new AbortController()) {
  state.loading = abortController;
}

// This assumes that we know any transactions have finished or we
// don't care about aborting.
function clearProjectsLoading (state) {
  state.loading = null;
}

function setProjectsTimestamp (state, timestamp = new Date()) {
  // NOTE: There is no `modified_on` property in the projects
  // result or in the database schema, but we should probably add
  // one.
  if (timestamp === true) {
    const tstamp = state.projects
      .map( event => event.modified_on )
      .reduce( (acc, cur) => acc > cur ? acc : cur );
    state.timestamp = tstamp ? new Date(tstamp) : new Date();
  } else {
    state.timestamp = timestamp;
  }
}

function setProjectsETag (state, etag) {
  state.etag = etag;
}

function abortProjectsLoading (state) {
  if (state.loading) {
    state.loading.abort();
  }
  state.loading = null;
}

export default {
  setProjects,
  setProjectsLoading,
  clearProjectsLoading,
  setProjectsTimestamp,
  setProjectsETag
};
