<template>
  <v-card>
    <v-card-title>Preplots</v-card-title>
    <v-card-subtitle>Preplot files location and format.</v-card-subtitle>
    <v-card-text>

      <v-expansion-panels v-model="panel">
        <v-expansion-panel v-for="(preplot, idx) in cwo" :key="idx">
          <v-expansion-panel-header>
            {{ titleFor(preplot) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <dougal-project-settings-preplots-preplot
              v-model="cwo[idx]"
              :root-path="value.rootPath"
            ></dougal-project-settings-preplots-preplot>

            <v-btn
              outlined
              color="red"
              title="Delete this preplot definition"
              @click.stop="deletePreplot(preplot)"
            >
              <v-icon left>mdi-delete-outline</v-icon>
              Delete
            </v-btn>


          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-btn
        class="mt-5"
        color="primary"
        :disabled="!lastPreplotIsValid"
        @click="newPreplot"
      >
        <v-icon left>mdi-file-document-plus-outline</v-icon>
        New preplot
      </v-btn>

    </v-card-text>
    <v-card-actions>
      <!--
      <v-btn
        color="primary"
        :disabled="!isValid"
        @click="save"
      >Save</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="warning"
        @click="reset"
      >Reset</v-btn>
      -->
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="back"
      >Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.sample {
  font-family: mono;
  white-space: pre;
}
</style>

<script>
import DougalProjectSettingsPreplotsPreplot from '@/components/project-settings/preplots-preplot';

export default {
  name: "DougalProjectSettingsPreplots",

  components: {
    DougalProjectSettingsPreplotsPreplot,
  },

  props: {
    value: Object
  },

  data () {
    return {
      panel: null
    }
  },

  computed: {
    // Current working object.
    // A shortcut so we don't have to specify the full path
    // on every input control. It also makes it easier to
    // change that path if necessary. Finally, it ensures that
    // the properties being modified are always available.
    cwo: {

      get () {
        if (this.value) {
          if (!this.value?.preplots) {
            deepSet(this.value, [ "preplots" ], []);
          }
          return this.value.preplots;
        } else {
          return [];
        }
      },

      set (v) {
        if (this.value) {
          deepSet(this.value, [ "preplots" ], v);
        }
      }

    },

    lastPreplotIsValid () {
      return this.cwo.length == 0 || this.validPreplot(this.cwo[this.cwo.length-1]);
    },

    isValid () {
      return this.cwo.every(preplot => this.validPreplot(preplot));
    }

  },

  methods: {

    titleFor (preplot) {
      let str = "";

      if (preplot?.path) {
        str += preplot.path;
      } else {
        const idx = this.cwo.findIndex(i => i == preplot);
        if (idx != -1) {
          str += `Preplot ${idx}`;
        } else {
          str += "Preplot <no path>";
        }
      }

      if (preplot?.class || preplot?.type) {
        str += " (" + [preplot.class, preplot.type].join("; ") + ")";
      }

      return str;
    },

    validPreplot (preplot) {

      const predefined_formats = [ "sps1", "sps2.1", "p190", "p111", "x-sl+csv" ]

      const common = (preplot?.path &&
        preplot?.type &&
        preplot?.class &&
        preplot?.fields);

      if (common) {
        if (predefined_formats.includes(preplot.class)) {
          // Predefined formats do not require a field definition
          return true;
        } else {
          return !!(preplot?.fields?.line_name &&
            preplot?.fields?.point_number &&
            preplot?.fields?.easting &&
            preplot?.fields?.northing);
        }
      }
      return false;
    },

    deletePreplot (preplot) {
      const idx = this.cwo.find(i => i == preplot);
      if (idx != -1) {
        this.cwo.splice(idx, 1);
      }
    },

    newPreplot () {
      if (this.lastPreplotIsValid) {
        const preplot = {
          path: "",
          type: "",
          format: "",
          fields: {},
        };
        this.cwo.push(preplot);
        this.panel = this.cwo.length - 1;
      }
    },


    reset () {
    },

    save () {
    },

    back () {
      this.$emit('close');
    }

  },

  mounted () {
    this.reset();
  }

}
</script>
