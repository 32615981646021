
function setProjectId (state, pid) {
  state.projectId = pid;
}

function setProjectName (state, name) {
  state.projectName = name;
}

function setProjectSchema (state, schema) {
  state.projectSchema = schema;
}

function setProjectConfiguration (state, configuration) {
  state.projectConfiguration = Object.freeze(configuration);
}

export default { setProjectId, setProjectName, setProjectSchema, setProjectConfiguration };
