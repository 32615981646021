
function setSnackText (state, text) {
  state.snackText = text;
}

function setSnackColour (state, colour) {
  state.snackColour = colour;
}

export default { setSnackText, setSnackColour };
