<template>
  <v-dialog :value="open" @input="$emit('close')">
    <v-card>
      <v-card-title>
      </v-card-title>

      <v-card-text>

        <v-text-field v-if="nameShown"
          label="Name"
          :disabled="!nameEditable"
          v-model.sync="value.name"
        ></v-text-field>

        <v-select v-if="typeShown"
          label="Type"
          :items="types"
          :disabled="!typeEditable"
          v-model.sync="value.type"
        ></v-select>

        <template v-if="valueShown">
          <v-text-field v-if="value.type == 'number' || value.type == 'bigint'"
            label="Value"
            type="number"
            v-model.sync="value.value"
          ></v-text-field>

          <v-textarea v-else-if="value.type == 'string'"
            label="Value"
            v-model.sync="value.value"
          ></v-textarea>

          <v-radio-group v-else-if="value.type == 'boolean'"
            v-model.sync="value.value"
          >
            <v-radio
              label="true"
              :value="true"
            ></v-radio>
            <v-radio
              label="false"
              :value="false"
            ></v-radio>
          </v-radio-group>
        </template>

      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="!canSave"
          @click="$emit('save')"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


</template>

<script>

export default {
  name: "DougalJsonBuilderPropertyDialog",

  props: {
    open: Boolean,
    value: Object,
    nameRequired: {type: Boolean, default: true},
    nameEditable: Boolean,
    nameShown: {type: Boolean, default: true},
    typeEditable: Boolean,
    typeShown: {type: Boolean, default: true},
    valueShown: {type: Boolean, default: true},
    serialisable: {type: Boolean, default: true},
    allowedTypes: Array
  },

  data () {
    return {
      //key: null,
      //type: null,
      allTypes: [
        "symbol",
        "string",
        "bigint",
        "number",
        "boolean",
        "undefined",
        "object",
        "null",
        "array"
      ],
      serialisableTypes: [
        "string",
        "number",
        "boolean",
        "object",
        "null",
        "array"
      ],
    };
  },

  computed: {

    types () {
      return this.allowedTypes
        ? this.allowedTypes
        : this.serialisable
          ? this.serialisableTypes
          : this.allTypes;
    },

    canSave () {
      return this.value.type && (this.value.name || this.nameRequired === false);
    }

  }


}

</script>
