<template>
  <v-dialog
    v-model="dialogOpen"
    @input="(e) => $emit('input', e)"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="adminaccess"
        title="Create a new project from scratch. Generally, it's preferable to clone an existing project (right-click → ‘Clone’)"
        small
        outlined
        color="warning"
        v-bind="attrs"
        v-on="on"
      >
        <span>Create new project</span>
        <v-icon right small>mdi-file-document-plus-outline</v-icon>
      </v-btn>
    </template>

    <dougal-project-settings-name-id-geodetics
      :value="newProjectDetails"
      @input="save"
      @close="dialogOpen = false"
    >
    </dougal-project-settings-name-id-geodetics>

  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DougalProjectSettingsNameIdGeodetics from '@/components/project-settings/name-id-geodetics'

export default {
  name: 'DougalAppBarExtensionProjectList',

  components: {
    DougalProjectSettingsNameIdGeodetics
  },

  data() {
    return {
      dialogOpen: false,
      newProjectDetails: {
        name: null,
        id: null,
        epsg: null
      }
    };
  },

  computed: {
    ...mapGetters(["adminaccess"])
  },

  methods: {
    async save (data) {
      this.dialogOpen = false;
      data.archived = true; // Make the project inactive to start with
      console.log("POST the new project data");
      console.log(data);

      const init = {
        method: "POST",
        body: data
      };
      const cb = (err, res) => {
        if (!err && res) {
          console.log(res);
          if (res.status == "201") {
            // Redirect to new project settings page
            const settingsUrl = `/projects/${data.id.toLowerCase()}/configuration`;
            this.$router.push(settingsUrl);

          }
        }
      };
      await this.api(["/project", init, cb]);
    },

    ...mapActions(["api"])
  }
}
</script>
