<template>

    <v-hover v-slot:default="{hover}" v-if="!isEmpty(item)">
      <span>
        <v-btn v-if="!isAccepted(item)"
          :class="{'text--disabled': !hover}"
          icon
          small
          color="primary"
          :title="isMultiple(item) ? 'Accept all' : 'Accept'"
          @click.stop="accept(item)">
          <v-icon small :color="isAccepted(item) ? 'green' : ''">
            {{ isMultiple(item) ? 'mdi-check-all' : 'mdi-check' }}
          </v-icon>
        </v-btn>
        <v-btn v-if="someAccepted(item)"
          :class="{'text--disabled': !hover}"
          icon
          small
          color="primary"
          :title="isMultiple(item) ? 'Restore all' : 'Restore'"
          @click.stop="unaccept(item)">
          <v-icon small>
            {{ isMultiple(item) ? 'mdi-restore' : 'mdi-restore' }}
          </v-icon>
        </v-btn>
      </span>
    </v-hover>

</template>

<script>

export default {
  name: 'DougalQcAcceptance',

  props: {
    item: { type: Object }
  },

  methods: {

    isAccepted (item) {
      if (item._children) {
        return item._children.every(child => this.isAccepted(child));
      }

      if (item.labels) {
        return item.labels.includes("QCAccepted");
      }

      return false;
    },

    someAccepted (item) {
      if (item._children) {
        return item._children.some(child => this.someAccepted(child));
      }

      if (item.labels) {
        return item.labels.includes("QCAccepted");
      }

      return false;
    },

    isEmpty (item) {
      return item._children?.length === 0;
    },

    isMultiple (item) {
      return item._children?.length;
    },

    action (action, item) {
      const items = [];

      const iterate = (item) => {
        if (item._kind == "point") {

          if (this.isAccepted(item)) {
            if (action == "unaccept") {
              items.push(item);
            }
          } else {
            if (action == "accept") {
              items.push(item);
            }
          }

        } else if (item._kind == "sequence" || item._kind == "test") {

          if (item._children) {

            for (const child of item._children) {
              iterate(child);
            }

          }

          if (item._shots) {

            for (const child of item._children) {
              iterate(child);
            }

          }

        }

      }

      iterate(item);
      return items;
    },

    accept (item) {
      const items = this.action('accept', item);
      if (items.length) {
        this.$emit('accept', items);
      }
    },

    unaccept (item) {
      const items = this.action('unaccept', item);
      if (items.length) {
        this.$emit('unaccept', items);
      }
    }

  }

}

</script>
