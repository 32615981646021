<template>
  <dougal-project-settings-file-matching-parameters
    title="Smartsource header data"
    subtitle="Smartsource header data files location and parameters."
    v-bind="{rootPath: value.rootPath}"
    v-bind.sync="bind"
    :is-valid="isValid"
    :save="save"
  >
  </dougal-project-settings-file-matching-parameters>

</template>

<style scoped>
.sample {
  font-family: mono;
  white-space: pre;
}
</style>

<script>
import { deepSet } from '@/lib/utils';
import DougalProjectSettingsFileMatchingParameters from '@/components/project-settings/file-matching-parameters';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "DougalProjectSettingsSmartsourceHeader",

  components: {
    DougalProjectSettingsFileMatchingParameters
  },

  props: {
    title: String,
    subtitle: String,
    value: Object
  },

  data () {
    return {
      tab: null,
    }
  },

  computed: {
    // Current working object.
    // A shortcut so we don't have to specify the full path
    // on every input control. It also makes it easier to
    // change that path if necessary. Finally, it ensures that
    // the properties being modified are always available.
    cwo: {

      get () {
        if (this.value) {
          if (!this.value?.raw?.source?.smsrc?.header) {
            deepSet(this.value, [ "raw", "source", "smsrc", "header" ], {
              globs: [ "**/*.hdr", "**/*.HDR" ],
              paths: [],
              lineNameInfo: {
                example: "",
                fields: {
                  line: {
                    length: 4,
                    type: "int"
                  },
                  sequence: {
                    length: 3,
                    type: "int"
                  }
                }
              }
            });
          }
          return this.value.raw.source.smsrc.header;
        } else {
          return {
              globs: [ "**/*.hdr", "**/*.HDR" ],
              paths: [],
              lineNameInfo: {
                example: "",
                fields: {
                }
              }
            };
        }
      },

      set (v) {
        if (this.value) {
          deepSet(this.value, [ "raw", "source", "smsrc", "header" ], v);
        }
      }
    },

    bind () {
      return {
        globs: this.cwo?.globs,
        paths: this.cwo?.paths,
        pattern: this.cwo?.pattern,
        lineNameInfo: this.cwo?.lineNameInfo
      };
    },

    isValid () {
      return !!(this.cwo?.paths.length && this.cwo?.globs.length && (
        this.cwo?.pattern?.regex &&
          ["direction", "line", "sequence"].every( i => this.cwo?.pattern?.captures?.includes(i) )) || (
        this.cwo?.lineNameInfo &&
          this.cwo?.lineNameInfo?.fields &&
            [ "line", "sequence", "incr" ].every( i =>
              ["offset", "length"].every( j => j in this.cwo?.lineNameInfo.fields[i] ))));
    }

  },

  watch: {
  },

  methods: {

    reset () {
    },

    save () {
    },

    back () {
      this.$emit('close');
    },
  },

  mounted () {
    this.reset();
  }

}
</script>
