
function sequences (state) {
  return state.sequences;
}

function sequenceCount (state) {
  return state.sequences?.length ?? 0;
}

function sequencesLoading (state) {
  return !!state.loading;
}

export default { sequences, sequenceCount, sequencesLoading };
