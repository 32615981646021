<!-- kate: replace-tabs on; indent-width 2; -->
<template>
<v-footer app>
	<dougal-help-dialog></dougal-help-dialog>

	<v-spacer></v-spacer>

	<small class="d-none d-sm-inline">&copy; {{year}} <a href="https://aaltronav.eu/" target="_blank" class="brand">Aaltronav</a></small>

	<v-spacer></v-spacer>

	<v-icon v-if="serverConnected" class="mr-6" small title="Connected to server">mdi-lan-connect</v-icon>
	<v-icon v-else class="mr-6" small color="red" title="Server connection lost (we'll reconnect automatically when the server comes back)">mdi-lan-disconnect</v-icon>

	<dougal-notifications-control class="mr-6"></dougal-notifications-control>

	<div title="Night mode">
      <v-switch
        class="ma-auto"
        flat
        hide-details
        v-model="$vuetify.theme.dark"
        append-icon="mdi-weather-night"
        ></v-switch>
    </div>
</v-footer>
</template>

<style>
@font-face {
  font-family: "Bank Gothic Medium";
  src: local("Bank Gothic Medium"), url("/public/fonts/bank-gothic-medium.woff");
}

.brand {
  font-family: "Bank Gothic Medium";
}
</style>

<script>
import { mapState } from 'vuex';

import DougalHelpDialog from '@/components/help-dialog';
import DougalNotificationsControl from '@/components/notifications-control';

export default {
  name: 'DougalFooter',

  components: {
    DougalHelpDialog,
    DougalNotificationsControl
  },

  computed: {
    year () {
      const date = new Date();
      return date.getUTCFullYear();
    },

    ...mapState({serverConnected: state => state.notify.serverConnected})
  }
};
</script>
