<template>

  <v-dialog v-model="open">

    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" title="Configure visible aspects">
        <v-icon small>mdi-wrench-outline</v-icon>
      </v-btn>

    </template>

    <v-card>
      <v-list nav subheader>

        <v-subheader>Visualisations</v-subheader>

        <v-list-item-group v-model="aspectsVisible" multiple>

          <v-list-item value="DougalGraphGunsPressure">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Series: Gun pressure</v-list-item-title>
                <v-list-item-subtitle>Array pressures weighted averages</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item value="DougalGraphGunsTiming">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Series: Gun timing</v-list-item-title>
                <v-list-item-subtitle>Array timing averages</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item value="DougalGraphGunsDepth">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Series: Gun depth</v-list-item-title>
                <v-list-item-subtitle>Array depths averages</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item value="DougalGraphGunsHeatmap">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Heatmap: Gun parameters</v-list-item-title>
                <v-list-item-subtitle>Detail of every gun × every shotpoint</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item value="DougalGraphArraysIJScatter">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Series: I/J error</v-list-item-title>
                <v-list-item-subtitle>Inline / crossline error</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>

        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn v-if="user" color="warning" text @click="save" :title="'Save as preference for user '+user.name+' on this computer (other users may have other defaults).'">Save as default</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="open=false">Close</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  name: "DougalGraphSettingsSequence",

  props: [
    "aspects"
  ],

  data () {
    return {
      open: false,
      aspectsVisible: this.aspects || []
    }
  },

  watch: {

    aspects () {
      // Update the aspects selection list iff the list
      // is not currently open.
      if (!this.open) {
        this.aspectsVisible = this.aspects;
      }
    }

  },

  computed: {
    ...mapGetters(['user', 'writeaccess', 'loading', 'serverEvent'])
  },

  methods: {
    save () {
      this.open = false;
      this.$nextTick( () => this.$emit("update:aspects", {aspects: [...this.aspectsVisible]}) );
    },

    reset () {
      this.aspectsVisible = this.aspects || [];
    }
  }
}

</script>
