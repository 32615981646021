<template>

  <div title="Notifications" v-if="visible">
    <v-switch
      class="ma-auto"
      flat
      hide-details
      v-model="notify"
      :loading="waiting"
      :disabled="disabled"
      append-icon="mdi-email-outline"
      ></v-switch>
  </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'DougalNotificationsControl',

  data () {
    return {
      visible: true,
      notify: false,
      waiting: false,
      disabled: false
    }
  },

  watch: {
    async notify (state) {
      if (state) {
        console.log("Checking for permission", Notification.permission);
        if (Notification.permission == "default") {
          console.log("Asking for permission");
          this.waiting = true;
          const response = await Notification.requestPermission();
          console.log("User says", response);
          this.waiting = false;
          if (response != "granted") {
            this.$nextTick( () => this.notify = false );
          }
          if (response == "denied") {
            this.disabled = true;
          }
        }
      } else {
        if (this.waiting) {
          this.waiting = false;
        }
      }
    },

    async serverEvent (event) {
      if (this.notify) {
        let notification;

        //console.log(event.channel);

        switch (event.channel) {
          case "realtime":
            break;
          case "event":
            //console.log("EVENT",JSON.parse(JSON.stringify(event)));
            let title, body, tag;
            if (event.payload.new) {
              tag = `${event.payload.schema}.${event.payload.table}.${event.payload.new.id}`;
              if (event.payload.table == "events_seq") {
                const point = event.payload.new.point;
                const sequence = event.payload.new.sequence;
                title = event.payload.operation == "INSERT"
                  ? `Dougal: Seq. ${sequence.toString().padStart(3, "0")} SP ${point}`
                  : event.payload.operation == "UPDATE"
                    ? `Dougal: Seq. ${sequence.toString().padStart(3, "0")} SP ${point} (update)`
                    : "";
                body = event.payload.new.remarks;
              } else if (event.payload.table == "events_timed") {
                const tstamp = event.payload.new.tstamp;
                title = event.payload.operation == "INSERT"
                  ? `Dougal: ${tstamp}`
                  : event.payload.operation == "UPDATE"
                    ? `Dougal: ${tstamp} (update)`
                    : "";
                body = event.payload.new.remarks;
              }

              if (title && body) {
                notification = new Notification(title, {body, tag});
              }
            }
            break;
        }
      }
    }

  },

  computed: {
    ...mapGetters(['loading', 'serverEvent']),
    ...mapState({projectSchema: state => state.project.projectSchema})
  },

  created () {
    this.visible = "Notification" in window;
    this.disabled = !this.visible|| Notification.permission == "denied";
  }

};

</script>

