<template>
    <v-card flat>
      <v-card-subtitle v-text="text">
      </v-card-subtitle>
      <v-card-text style="max-height:350px;overflow:scroll;">
        <v-form>
          <template v-for="key in fieldKeys">
            <template v-if="schema[key].enum">
              <v-select v-if="schema[key].type == 'number'" :key="key"
                v-model.number="fieldValues[key]"
                :items="schema[key].enum"
                :label="schema[key].title"
                :hint="schema[key].description"
                @input="updateFieldValue(key, Number($event))"
              ></v-select>
              <v-select v-else :key="key"
                v-model="fieldValues[key]"
                :items="schema[key].enum"
                :label="schema[key].title"
                :hint="schema[key].description"
                @input="updateFieldValue(key, $event)"
              ></v-select>
            </template>
            <template v-else>
              <v-text-field v-if="schema[key].type == 'number'" :key="key"
                v-model.number="fieldValues[key]"
                type="number"
                :min="schema[key].minimum"
                :max="schema[key].maximum"
                :step="schema[key].multiplier"
                :label="schema[key].title"
                :hint="schema[key].description"
                @input="updateFieldValue(key, Number($event))"
              >
              </v-text-field>
              <v-text-field v-else-if="schema[key].type == 'string'" :key="key"
                v-model="fieldValues[key]"
                :label="schema[key].title"
                :hint="schema[key].description"
                @input="updateFieldValue(key, $event)"
              >
              </v-text-field>
              <v-checkbox v-else-if="schema[key].type == 'boolean'" :key="key"
                v-model="fieldValues[key]"
                :label="schema[key].title"
                :hint="schema[key].description"
                @change="updateFieldValue(key, $event)"
              >
              </v-checkbox>
              <v-text-field v-else :key="key"
                v-model="fieldValues[key]"
                :label="schema[key].title"
                :hint="schema[key].description"
                @input="updateFieldValue(key, $event)"
              >
              </v-text-field>
            </template>
          </template>
        </v-form>
      </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "DougalEventPropertiesEdit",

  components: {
  },

  props: {
    value: String,
    template: String,
    schema: Object,
    values: Array
  },

  data () {
    return {
    }
  },

  computed: {

    fieldKeys () {
      return Object.entries(this.schema).sort((a, b) => a[1].title > b[1].title ? 1 : -1).map(i => i[0]);
    },

    fieldValues () {
      const keys = Object.keys(this.schema ?? this.values);
      return Object.fromEntries(
        keys.map( (k, idx) =>
          [ k, this.values?.[idx]  ?? this.schema[k].default ]));
    },

    /*
    fields () {
      // TODO Remove this and rename fields → schema
      return this.schema;
    },
    */

    text () {
      if (this.template) {
        const rx = /{{([a-z_][a-z0-9_]*)}}/ig;
        return this.template.replace(rx, (match, p1) => this.fieldValues[p1] ?? "(n/a)");
      }
    }

  },

  watch: {

    values () {
      this.$emit("input", this.text);
    },

    template () {
      this.$emit("input", this.text);
    },

    schema () {
      this.$emit("input", this.text);
    }

  },

  methods: {
    updateFieldValue(key, ev) {
      const values = {...this.fieldValues};
      values[key] = ev;
      this.$emit("update:values", Object.values(values));
    }
  },

  mount () {
  }
}

</script>
