<template>
  <v-card>
    <v-card-title>ASAQC</v-card-title>
    <v-card-subtitle>Equinor's cloud API configuration.</v-card-subtitle>
    <v-card-text>
      <v-form>
        <v-text-field
          label="ASAQC ID"
          hint="ID number for this survey in ASAQC"
          persistent-hint
          type="number"
          v-model.number="cwo.id"
        >
        </v-text-field>
        <v-text-field
          label="IMO"
          hint="Project vessel's International Maritime Organisation's identification number"
          persistent-hint
          v-model.number="cwo.imo"
        >
        </v-text-field>
        <v-text-field
          label="MMSI"
          hint="Maritime Mobile Service Identities (MMSI) number"
          persistent-hint
          v-model.number="cwo.mmsi"
        >
        </v-text-field>
        <v-text-field
          label="Subscription key"
          hint="Key to authenticate to ASAQC, provided by Equinor"
          persistent-hint
          :type="subscriptionKeyVisible ? 'text' : 'password'"
          :append-icon="subscriptionKeyVisible ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="cwo.subscriptionKey"
          @click:append="subscriptionKeyVisible = !subscriptionKeyVisible"
        >
        </v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!--
      <v-btn
        color="primary"
        @click="save"
      >Save</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="warning"
        @click="reset"
      >Reset</v-btn>
      -->
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="back"
      >Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { deepSet } from '@/lib/utils';

export default {
  name: "DougalProjectSettingsASAQC",

  props: {
    value: Object
  },

  data () {
    return {
      subscriptionKeyVisible: false
    }
  },

  computed: {
    // Current working object.
    // A shortcut so we don't have to specify the full path
    // on every input control. It also makes it easier to
    // change that path if necessary. Finally, it ensures that
    // the properties being modified are always available.
    cwo: {

      get () {
        if (this.value) {
          if (!this.value?.cloud?.asaqc) {
            deepSet(this.value, [ "cloud", "asaqc" ], {
              id: null,
              imo: null,
              mmsi: null,
              subscriptionKey: null
            });
          }
          return this.value.cloud.asaqc;
        } else {
          return {};
        }
      },

      set (v) {
        if (this.value) {
          deepSet(this.value, [ "cloud", "asaqc" ], v);
        }
      }

    }
  },

  methods: {

    reset () {
    },

    save () {
    },

    back () {
      this.$emit('close');
    }

  },

  mounted () {
    this.reset();
  }

}
</script>
