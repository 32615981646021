<template>
<v-dialog
  v-model="dialog"
  style="z-index:2020;"
>
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      class="hover"
      icon
      small
      title="This entry has edits. Click to view history."
      :disabled="disabled"
      v-on="on"
    >
      <v-icon small>mdi-playlist-edit</v-icon>
    </v-btn>
  </template>

  <v-card>
    <v-card-title class="headline">
      Event history
    </v-card-title>

    <v-card-text>
      <p>Event ID: {{ id }}</p>
      <v-data-table
      dense
      class="small"
      :headers="headers"
      :items="rows"
      item-key="uid"
      sort-by="uid"
      :sort-desc="true"
      :loading="loading"
      fixed-header
      :footer-props='{itemsPerPageOptions: [ 10, 25, 50, 100, 500, -1 ]}'
      >

        <template v-slot:item.tstamp="{value}">
          <span style="white-space:nowrap;" v-if="value">
            {{ value.replace(/(.{10})T(.{8}).{4}Z$/, "$1 $2") }}
          </span>
        </template>

        <template v-slot:item.remarks="{item}">
          <template>
            <div>
              <span v-if="item.labels.length">
                <v-chip v-for="label in item.labels"
                  class="mr-1 px-2 underline-on-hover"
                  x-small
                  :color="labels[label] && labels[label].view.colour"
                  :title="labels[label] && labels[label].view.description"
                  :key="label"
                  :href="$route.path+'?label='+encodeURIComponent(label)"
                >{{label}}</v-chip>
              </span>
              <span v-html="$options.filters.markdownInline(item.remarks)">
              </span>
            </div>
          </template>
        </template>

        <template v-slot:item.valid_from="{item}">
          <span style="white-space:nowrap;" v-if="item.validity[1]">
            {{ item.validity[1].replace(/(.{10})[T ](.{8}).{4,}(Z|[+-][\d]+)$/, "$1 $2") }}
          </span>
          <span v-else>
            ∞
          </span>
        </template>

        <template v-slot:item.valid_until="{item}">
          <span style="white-space:nowrap;" v-if="item.validity[2]">
            {{ item.validity[2].replace(/(.{10})[T ](.{8}).{4,}(Z|[+-][\d]+)$/, "$1 $2") }}
          </span>
          <span v-else>
            ∞
          </span>
        </template>

      <!-- Actions column -->
      <template v-slot:item.actions="{ item }">
        <div style="white-space:nowrap;">
          <!-- NOTE Kind of cheating here by assuming that there will be
            no items with *future* validity. -->
          <template v-if="item.validity[2]">
            <v-btn v-if="!item.meta.readonly"
              class="hover"
              icon
              small
              title="Restore"
              :disabled="loading"
              @click=restoreEvent(item)
            >
              <v-icon small>mdi-history</v-icon>
            </v-btn>
            <v-btn v-else
              class="hover off"
              icon
              small
              title="This event is read-only"
              :disabled="loading"
            >
              <v-icon small>mdi-lock-reset</v-icon>
            </v-btn>
          </template>
        </div>
      </template>

      </v-data-table>

    </v-card-text>
  </v-card>

</v-dialog>

</template>

<style scoped>
.hover {
  opacity: 0.4;
}
.hover:hover {
  opacity: 1;
}
.hover.off:hover {
  opacity: 0.4;
}

.small >>> td, .small >>> th {
  font-size: 85% !important;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DougalEventEditHistory',

  props: {
    id: { type: Number },
    disabled: { type: Boolean, default: false },
    labels: { default: {} }
  },

  data () {
    return {
      dialog: false,
      rows: [],
      headers: [
        {
          value: "tstamp",
          text: "Timestamp",
          width: "20ex"
        },
        {
          value: "sequence",
          text: "Sequence",
          align: "end",
          width: "10ex"
        },
        {
          value: "point",
          text: "Shotpoint",
          align: "end",
          width: "10ex"
        },
        {
          value: "remarks",
          text: "Text",
          width: "100%"
        },
        {
          value: "valid_from",
          text: "Valid From"
        },
        {
          value: "valid_until",
          text: "Valid Until"
        },
        {
          value: "actions",
          text: "Actions",
          sortable: false
        }
      ]
    };
  },

  computed: {
    ...mapGetters(['loading', 'serverEvent'])
  },

  watch: {
    dialog (val) {
      if (!val) {
        this.rows = [];
      } else {
        this.getEventHistory();
      }
    },

    async serverEvent (event) {
      if (event.channel == "event" &&
        (event.payload?.new?.id ?? event.payload?.old?.id) == this.id) {
        // The event that we're viewing has been refreshed (possibly by us)
        this.getEventHistory();
      }
    }
  },

  methods: {

    async getEventHistory () {
      const url = `/project/${this.$route.params.project}/event/${this.id}`;
      this.rows = (await this.api([url]) || []).map(row => {
        row.valid_from = row.validity[1] ?? -Infinity;
        row.valid_until = row.validity[2] ?? +Infinity;
        return row;
      });
    },

    async restoreEvent (item) {
      if (item.id) {
        const url =  `/project/${this.$route.params.project}/event/${item.id}`;
        await this.api([url, {
          method: "PUT",
          body: item // NOTE Sending extra attributes in the body may cause trouble down the line
        }]);
      }
    },

    ...mapActions(["api"])

  }

};
</script>
