
function projectId (state) {
  return state.projectId;
}

function projectName (state) {
  return state.projectName;
}

function projectSchema (state) {
  return state.projectSchema;
}

function projectConfiguration (state) {
  return state.projectConfiguration;
}

export default { projectId, projectName, projectSchema, projectConfiguration };
