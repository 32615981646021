<template>
  <v-row
    dense
    no-gutters
    align="center"
  >

    <v-col cols="1">
      <slot name="prepend"></slot>
    </v-col>

    <v-col cols="2">
      <v-chip outlined label small :color="colour || getHSLColourFor(key)">{{name}}</v-chip>
    </v-col>

    <v-col cols="4">
      <v-text-field
        dense
        label="Column"
        type="number"
        min="0"
        clearable
        :value="value.column"
        @input="$emit('input', {...value, column: Number($event)})"
      >
        <template v-slot:append-outer>
          <dougal-field-content-dialog
            :readonly="readonly"
            :value="value"
            @input="$emit('input', $event)"
          ></dougal-field-content-dialog>
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="1">
      <slot name="append"></slot>
    </v-col>

  </v-row>
</template>

<style scoped>
</style>

<script>
import { parse } from 'csv-parse/sync'
import { getHSLColourFor } from '@/lib/hsl'
import DougalFieldContentDialog from '../fields/field-content-dialog'

export default {
  name: "DougalDelimitedStringDecoderField",

  components: {
    //DougalFixedStringDecoderField,
    DougalFieldContentDialog
  },

  props: {
    value: Object,
    name: String,
    colour: String,
    readonly: Boolean,
  },

  data () {
    return {
    }
  },

  computed: {
  },

  watch: {
  },

  methods: {
    getHSLColourFor: getHSLColourFor.bind(this),
  },

}
</script>
