
function planRemarks (state) {
  return state.remarks;
}

function plannedSequences (state) {
  return state.sequences;
}

function plannedSequenceCount (state) {
  return state.sequences?.length ?? 0;
}

function plannedSequencesLoading (state) {
  return !!state.loading;
}

export default { planRemarks, plannedSequences, plannedSequenceCount, plannedSequencesLoading };
