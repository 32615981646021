<template>
  <v-card>
    <v-card-title>Groups</v-card-title>
    <v-card-subtitle>For <abbr title="Permanent Reservoir Monitoring">PRM</abbr> and 4D operations, each project can be assigned to one or more groups sharing the same (or substantially the same) preplots.</v-card-subtitle>
    <v-card-text>
      <v-form>
        <v-combobox
          v-model="groups"
          :items="items"
          multiple
          :search-input.sync="search"
          :hide-no-data="!search"
          hide-selected
          chips
          hint="Project group(s). Type a value to create a new group (case sensitive)"
          persistent-hint
        >
          <template v-slot:no-data>
            <v-list-item>
              <small>New group: </small>
              <v-chip class="ml-3"
                :color="`grey lighten-2`"
                light
                label
                small
              >
                {{ search }}
              </v-chip>
            </v-list-item>
          </template>
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-if="item === Object(item)"
              v-bind="attrs"
              :color="`${item.colour} lighten-3`"
              :input-value="selected"
              label
              small
            >
              <span class="pr-2">
                {{ item.text }}
              </span>
              <v-icon
                small
                @click="parent.selectItem(item)"
              >
                $delete
              </v-icon>
            </v-chip>
          </template>
          <template v-slot:item="{ index, item }">
            <v-chip
              :color="`${item.colour} lighten-3`"
              label
              small
            >{{ item.text }}</v-chip>
          </template>
        </v-combobox>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!--
      <v-btn
        color="primary"
        @click="save"
      >Save</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="warning"
        @click="reset"
      >Reset</v-btn>
      -->
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="back"
      >Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { deepSet } from '@/lib/utils';

export default {
  name: "DougalProjectSettingsGroups",

  props: [ "value" ],

  data () {
    return {
      search: null
    }
  },

  computed: {

    colours () {
      return [ "green", "purple", "indigo", "cyan", "teal", "orange" ];
    },

    nextColour () {
      // FIXME Fix colour when adding a new group
      return this.colours[(this.items.length + (this.value?.groups?.length ?? 0)) % this.colours.length];
    },

    items () {
      return this.projectGroups.map((item, idx) => {
        return {
          text: item,
          colour: this.colours[idx % this.colours.length]
        };
      });
    },

    groups: {

      get () {
        return this.value?.groups?.map(i => {
          return typeof i === "string"
            ? { text: i, colour: this.nextColour}
            : i
        }) ?? [];
      },

      set (v) {
        if (this.value) {
          this.value.groups = v?.map( i => i?.text ?? i );
        }
      }

    },

    ...mapGetters(["projectGroups"])
  },

  methods: {

    reset () {
    },

    save () {
    },

    back () {
      this.$emit('close');
    }

  },

  mounted () {
    this.reset();
  }

}
</script>
