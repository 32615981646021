export const gunArrays = {
  1: {
    min: {
      fillcolor: "rgba(200, 230, 201, 0.2)",
      line: {color: "rgba(129, 199, 132, 0.3)", shape: "spline"},
      showlegend: false,
      name: "Array 1 (min.)",
      hoverinfo: "skip"
    },
    avg: {
      fillcolor: "rgba(200, 230, 201, 0.2)",
      line: {color: "rgba(129, 199, 132, 0.9)", shape: "spline"},
      name: "Array 1 (avg.)"
    },
    max: {
      fillcolor: "rgba(200, 230, 201, 0.2)",
      line: {color: "rgba(129, 199, 132, 0.4)", shape: "spline"},
      showlegend: false,
      name: "Array 1 (max.)",
      hoverinfo: "skip"
    },
    out: {
      name: "Array 1 outliers",
      line: {color: "rgba(129, 199, 166, 0.7)"},
      fillcolor: "rgba(129, 199, 166, 0.5)"
    }
  },
  2: {
    min: {
      fillcolor: "rgba(255, 205, 210, 0.2)",
      line: {color: "rgba(229, 115, 115, 0.3)", shape: "spline"},
      showlegend: false,
      name: "Array 2 (min.)",
      hoverinfo: "skip"
    },
    avg: {
      fillcolor: "rgba(255, 205, 210, 0.2)",
      line: {color: "rgba(229, 115, 115, 0.9)", shape: "spline"},
      name: "Array 2 (avg.)"
    },
    max: {
      fillcolor: "rgba(255, 205, 210, 0.2)",
      line: {color: "rgba(229, 115, 115, 0.4)", shape: "spline"},
      showlegend: false,
      name: "Array 2 (max.)",
      hoverinfo: "skip"
    },
    out: {
      name: "Array 2 outliers",
      line: {color: "rgba(229, 153, 115, 0.7)"},
      fillcolor: "rgba(229, 153, 115, 0.5)"
    }
  },
  3: {
    min: {
      fillcolor: "",
      line: {color: "", shape: "spline"},
      showlegend: false,
      name: "Array 3 (min.)",
      hoverinfo: "skip"
    },
    avg: {
      fillcolor: "",
      line: {color: "", shape: "spline"},
      name: "Array 3 (avg.)"
    },
    max: {
      fillcolor: "",
      line: {color: "", shape: "spline"},
      showlegend: false,
      name: "Array 3 (max.)",
      hoverinfo: "skip"
    },
    out: {
      name: "Array 3 outliers",
      //fillcolor: ""
    }
  }
};

export const gunArrayViolins = {
  1: {
    value: {line: {color: "rgba(129, 199, 132, 0.9)"}}
  },
  2: {
    value: {line: {color: "rgba(229, 115, 115, 0.9)"}}
  }
};
