
function setCookie (state, cookie) {
  state.cookie = cookie;
}

function setUser (state, user) {
  state.user = user;
}

function setPreferences (state, preferences) {
  state.preferences = preferences;
}

export default { setCookie, setUser, setPreferences };
