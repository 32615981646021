
function events (state) {
  return state.events;
}

function eventCount (state) {
  return state.events?.length ?? 0;
}

function eventsLoading (state) {
  return !!state.loading;
}

export default { events, eventCount, eventsLoading };
