
function lines (state) {
  return state.lines;
}

function lineCount (state) {
  return state.lines?.length ?? 0;
}

function linesLoading (state) {
  return !!state.loading;
}

export default { lines, lineCount, linesLoading };
