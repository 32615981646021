var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Groups")]),_c('v-card-subtitle',[_vm._v("For "),_c('abbr',{attrs:{"title":"Permanent Reservoir Monitoring"}},[_vm._v("PRM")]),_vm._v(" and 4D operations, each project can be assigned to one or more groups sharing the same (or substantially the same) preplots.")]),_c('v-card-text',[_c('v-form',[_c('v-combobox',{attrs:{"items":_vm.items,"multiple":"","search-input":_vm.search,"hide-no-data":!_vm.search,"hide-selected":"","chips":"","hint":"Project group(s). Type a value to create a new group (case sensitive)","persistent-hint":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('small',[_vm._v("New group: ")]),_c('v-chip',{staticClass:"ml-3",attrs:{"color":"grey lighten-2","light":"","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":((item.colour) + " lighten-3"),"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-chip',{attrs:{"color":((item.colour) + " lighten-3"),"label":"","small":""}},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.groups),callback:function ($$v) {_vm.groups=$$v},expression:"groups"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.back}},[_vm._v("Back")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }