

export default function FormatTimestamp (str) {
  const d = new Date(str);
  if (isNaN(d)) {
    return str;
  } else {
    // Get rid of milliseconds
    return d.toISOString().substring(0,19)+"Z";
  }
}
