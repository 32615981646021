<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="mx-auto" max-width="600" v-if="projectInfo">
          <v-card-title>
            {{ projectInfo.name }}
          </v-card-title>
          <v-card-subtitle v-if="projectInfo.fsp">
            Started on {{ projectInfo.fsp.tstamp.substring(0,10) }}
            at {{
              projectInfo.fsp.tstamp.substring(11, 19) +
              (projectInfo.fsp.tstamp.substring(projectInfo.fsp.tstamp.length-6) == "+00:00" ? "Z" : " LT")
            }}
          </v-card-subtitle>
          <v-card-subtitle v-else>
            No production yet
          </v-card-subtitle>
          <v-card-text>
            <span class="headline" :class="textProgressColour">
            {{ completionPercentage.toFixed(1) + "% completed" }}
            </span><br/>
            <span v-if="completionPercentage == 100">
              Completed on {{ projectInfo.lsp.tstamp.substring(0,10) }}
            at {{ projectInfo.lsp.tstamp.substring(11, 19) }}
            {{ projectInfo.lsp.tstamp.substring(projectInfo.lsp.tstamp.length-6) == "+00:00" ? "Z" : " LT" }}
            </span>
            <span v-else-if="completionPercentage > 2">
              Estimated end
              {{ estimatedEndTimestamp.toISOString().substring(0,10) }}
              at
              {{ estimatedEndTimestamp.toISOString().substring(11,19) }}Z
            </span>
          </v-card-text>

          <v-list class="transparent">

            <v-list-item>
              <v-list-item-title class="">
                Preplot lines
              </v-list-item-title>
              <v-list-item-subtitle class="text-right">
                {{ projectInfo.lines }}
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="">
                Preplot shots
              </v-list-item-title>
              <v-list-item-subtitle class="text-right">
                {{ projectInfo.total }}
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="">
                Last sequence
              </v-list-item-title>
              <v-list-item-subtitle class="text-right">
                <span v-if="projectInfo.seq_raw == projectInfo.seq_final">
                  {{ projectInfo.seq_final }}
                </span>
                <span v-else>
                  {{ projectInfo.seq_final }} (processed);
                  {{ projectInfo.seq_raw }} (acquired)
                </span>
              </v-list-item-subtitle>
            </v-list-item>

            <v-divider></v-divider>

            <!--
            <v-card-text class="headline small">
              Processed data
            </v-card-text>
            -->

            <v-list-item>
              <v-list-item-title class="">
                Production distance
              </v-list-item-title>
              <v-list-item-subtitle class="text-right">
                <span>
                  {{ (projectInfo.prod_distance/1000).toFixed(2) }} km
                </span>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="">
                Shots processed
              </v-list-item-title>
              <v-list-item-subtitle class="text-right">
                <span>
                  {{ projectInfo.prime + projectInfo.other }}
                </span>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item v-if="projectInfo.prime">
              <v-list-item-title class="">
                Of which prime
              </v-list-item-title>
              <v-list-item-subtitle class="text-right">
                <span>
                  ({{
                    projectInfo.prime
                      ? ((1 - projectInfo.other / projectInfo.prime)*100).toFixed(1)
                      : "0.0"
                  }}%) {{projectInfo.prime}}
                </span>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="">
                Points marked as <abbr title="Not to be acquired">NTBA</abbr>
              </v-list-item-title>
              <v-list-item-subtitle class="text-right">
                <span>
                  {{ projectInfo.ntba }}
                </span>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="">
                Shots remaining
              </v-list-item-title>
              <v-list-item-subtitle class="text-right">
                <span>
                  {{ projectInfo.remaining }}
                </span>
              </v-list-item-subtitle>
            </v-list-item>


          </v-list>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "ProjectSummary",

  data () {
    return {
      projectInfo: null
    };
  },

  computed: {

    completionPercentage () {
      return this.projectInfo && this.projectInfo.total
        ? (1 - (this.projectInfo.remaining / this.projectInfo.total))*100
        : 0;
    },

    progressColour () {

      const colours = [
        "orange darken-4",
        "orange darken-3",
        "orange lighten-1",
        "orange accent-1",
        "lime accent-2",
        "lime accent-3",
        "green lighten-4",
        "green lighten-2",
        "green",
      ];

      const index = Math.min(Math.round(this.completionPercentage / (colours.length+1)), colours.length-1);

      return this.completionPercentage ? colours[index] : "blue-grey";
    },

    textProgressColour () {
      const c = this.progressColour.split(" ");
      return c.length > 1
        ? c[0]+"--text text--"
        : c[0]+"--text";
    },

    estimatedEndTimestamp () {
      const ts0 = new Date(this.projectInfo.fsp.tstamp);
      //const ts1 = new Date(this.projectInfo.lsp.tstamp);
      const ts1 = new Date();
      const pct = this.completionPercentage/100;

      if (pct && ts0 && ts1) {
        if (pct>=1) {
          return new Date(ts1);
        } else {
          const tsc = (ts1-ts0)/pct + ts0.valueOf();
          return new Date(tsc);
        }
      }
      // else return undefined
    },

    ...mapGetters(['loading'])
  },

  methods: {
    async getProjectInfo () {
      this.projectInfo = await this.api([`/project/${this.$route.params.project}`]);
    },

    ...mapActions(["api"])

  },

  mounted () {
    this.getProjectInfo();
  }

}

</script>
