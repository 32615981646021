<template>
  <v-dialog
    max-width="600"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        class="ml-3"
        small
        :light="$vuetify.theme.isDark"
        :dark="!$vuetify.theme.isDark"
        :title="getFriendlyTypeName(value.type)"
        :color="getHSLColourFor(value.type||'str', .4, .5)"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>{{ getTypeIcon(value.type||'str') }}</v-icon>
        <v-icon small v-if="value.enum"
          :title="'Values: '+Object.entries(value.enum).map(i => `${i[0]}=${i[1]}`).join('; ')+'\nDefault: '+value.default"
        >mdi-format-list-group</v-icon>
      </v-chip>
    </template>

    <dougal-field-content
      :readonly="readonly"
      :value="value"
      @input="$emit('input', $event)"
    ></dougal-field-content>

  </v-dialog>
</template>

<script>
import DougalFieldContent from './field-content'

export default {

  name: "DougalFieldContentDialog",

  components: {
    DougalFieldContent
  },

  props: {
    value: Object,
    readonly: Boolean
  },

  data () {
    return {
    };
  },

  computed: {
  },

  methods: {

    getFriendlyTypeName (type) {
      switch (type) {
        case "str":
          return "Text";
        case "int":
          return "Integer";
        case "float":
          return "Float";
        case "bool":
          return "Boolean";
        default:
          return type ?? "Text (default)";
      }
    },

    getTypeIcon (type) {
      switch (type) {
        case "str":
          return "mdi-format-text-variant";
        case "int":
          return "mdi-numeric";
        case "float":
          return "mdi-decimal";
        case "bool":
          return "mdi-format-list-checks";
        default:
          return "mdi-format-text";
      }
    },

    getHSLColourFor (str, saturation = 1, lightness = 0.25, offset = 0) {

      function getHash (v) {
        return [...v].reduce( (acc, cur) => String(cur).charCodeAt(0) + ((acc << 5) - acc), 0 );
      }

      const h = (getHash(str) + offset) % 360;
      const s = saturation * 100;
      const l = this.$vuetify.theme.isDark
        ? (1-lightness) * 100
        : lightness * 100;

      return `hsl(${h},${s}%,${l}%)`;
    }

  }

}

</script>
