var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticStyle:{"z-index":"2020"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"hover",attrs:{"icon":"","small":"","title":"This entry has edits. Click to view history.","disabled":_vm.disabled}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-playlist-edit")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Event history ")]),_c('v-card-text',[_c('p',[_vm._v("Event ID: "+_vm._s(_vm.id))]),_c('v-data-table',{staticClass:"small",attrs:{"dense":"","headers":_vm.headers,"items":_vm.rows,"item-key":"uid","sort-by":"uid","sort-desc":true,"loading":_vm.loading,"fixed-header":"","footer-props":{itemsPerPageOptions: [ 10, 25, 50, 100, 500, -1 ]}},scopedSlots:_vm._u([{key:"item.tstamp",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(value.replace(/(.{10})T(.{8}).{4}Z$/, "$1 $2"))+" ")]):_vm._e()]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [[_c('div',[(item.labels.length)?_c('span',_vm._l((item.labels),function(label){return _c('v-chip',{key:label,staticClass:"mr-1 px-2 underline-on-hover",attrs:{"x-small":"","color":_vm.labels[label] && _vm.labels[label].view.colour,"title":_vm.labels[label] && _vm.labels[label].view.description,"href":_vm.$route.path+'?label='+encodeURIComponent(label)}},[_vm._v(_vm._s(label))])}),1):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.markdownInline(item.remarks))}})])]]}},{key:"item.valid_from",fn:function(ref){
var item = ref.item;
return [(item.validity[1])?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.validity[1].replace(/(.{10})[T ](.{8}).{4,}(Z|[+-][\d]+)$/, "$1 $2"))+" ")]):_c('span',[_vm._v(" ∞ ")])]}},{key:"item.valid_until",fn:function(ref){
var item = ref.item;
return [(item.validity[2])?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.validity[2].replace(/(.{10})[T ](.{8}).{4,}(Z|[+-][\d]+)$/, "$1 $2"))+" ")]):_c('span',[_vm._v(" ∞ ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[(item.validity[2])?[(!item.meta.readonly)?_c('v-btn',{staticClass:"hover",attrs:{"icon":"","small":"","title":"Restore","disabled":_vm.loading},on:{"click":function($event){return _vm.restoreEvent(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-history")])],1):_c('v-btn',{staticClass:"hover off",attrs:{"icon":"","small":"","title":"This event is read-only","disabled":_vm.loading}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lock-reset")])],1)]:_vm._e()],2)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }