<template>
  <v-card>
    <v-card-title>Online line name</v-card-title>
    <v-card-subtitle>Line name decoding configuration for real-time data</v-card-subtitle>
    <v-card-text>
      <v-form>
        <v-text-field
          label="Example file name"
          hint="Enter the name of a representative file to make it easier to visualise your configuration"
          persistent-hint
          v-model="cwo.lineNameInfo.example"
        ></v-text-field>

        <dougal-fixed-string-decoder
          title="Line name format"
          subtitle="Format of line names as configured in the navigation system"
          :multiline="true"
          :text="cwo.lineNameInfo.example"
          :fields="cwo.lineNameInfo.fields"
        ></dougal-fixed-string-decoder>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!--
      <v-btn
        color="primary"
        @click="save"
      >Save</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="warning"
        @click="reset"
      >Reset</v-btn>
      -->
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="back"
      >Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.sample {
  font-family: mono;
  white-space: pre;
}
</style>

<script>
import { deepSet } from '@/lib/utils';
import DougalFixedStringDecoder from '@/components/decoder/fixed-string-decoder';

export default {
  name: "DougalProjectSettingsOnlineLineNameFormat",

  components: {
    DougalFixedStringDecoder
  },

  props: {
    value: Object
  },

  data () {
    return {
    }
  },

  computed: {
    // Current working object.
    // A shortcut so we don't have to specify the full path
    // on every input control. It also makes it easier to
    // change that path if necessary. Finally, it ensures that
    // the properties being modified are always available.
    cwo: {

      get () {
        if (this.value) {
          if (!this.value?.online?.line) {
            deepSet(this.value, [ "online", "line" ], {
              lineNameInfo: {
                example: "",
                fields: {
                  line: {
                    length: 4,
                    type: "int"
                  },
                  sequence: {
                    length: 3,
                    type: "int"
                  },
                  incr: {
                    length: 1,
                    type: "bool"
                  },
                  attempt: {
                    length: 1,
                    type: "int"
                  }
                }
              }
            });
          }
          return this.value.online.line;
        } else {
          return {};
        }
      },

      set (v) {
        if (this.value) {
          deepSet(this.value, [ "online", "line" ], v);
        }
      }

    }
  },

  methods: {

    reset () {
    },

    save () {
    },

    back () {
      this.$emit('close');
    }

  },

  mounted () {
    this.reset();
  }

}
</script>
