
function setLabels (state, labels) {
  // We don't need or want the events array to be reactive, since
  // it can be tens of thousands of items long.
  state.labels = Object.freeze(labels);
}

function setLabelsLoading (state, abortController = new AbortController()) {
  state.loading = abortController;
}

// This assumes that we know any transactions have finished or we
// don't care about aborting.
function clearLabelsLoading (state) {
  state.loading = null;
}

function setLabelsTimestamp (state, timestamp = new Date()) {
  // NOTE: There is no `modified_on` property in the labels
  // result or in the database schema, but we could add
  // one.
  if (timestamp === true) {
    const tstamp = state.labels
      .map( i => i.modified_on )
      .reduce( (acc, cur) => acc > cur ? acc : cur );
    state.timestamp = tstamp ? new Date(tstamp) : new Date();
  } else {
    state.timestamp = timestamp;
  }
}

function setLabelsETag (state, etag) {
  state.etag = etag;
}

function abortLabelsLoading (state) {
  if (state.loading) {
    state.loading.abort();
  }
  state.loading = null;
}

export default {
  setLabels,
  setLabelsLoading,
  clearLabelsLoading,
  setLabelsTimestamp,
  setLabelsETag
};
