<template>
  <v-tabs :value="tab" show-arrows v-if="page != 'configuration'">
    <v-tab v-for="tab, index in tabs" :key="index" link :to="tabLink(tab.href)" v-text="tab.text"></v-tab>
    <template v-if="adminaccess">
      <v-spacer></v-spacer>
      <v-tab :to="tabLink('configuration')" class="orange--text darken-3" title="Edit project settings"><v-icon small left color="orange darken-3">mdi-cog-outline</v-icon> Settings</v-tab>
    </template>
  </v-tabs>
  <v-tabs optional :value="0" show-arrows align-with-title v-else>
    <v-tab>Project settings</v-tab>
    <v-spacer></v-spacer>
    <v-tab :to="tabLink('summary')">Go to project</v-tab>
  </v-tabs>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DougalAppBarExtensionProject',
  data() {
    return {
      tabs: [
        { href: "summary", text: "Summary" },
        { href: "lines", text: "Lines" },
        { href: "plan", text: "Plan" },
        { href: "sequences", text: "Sequences" },
        { href: "calendar", text: "Calendar" },
        { href: "log", text: "Log" },
        { href: "qc", text: "QC" },
        { href: "graphs", text: "Graphs" },
        { href: "map", text: "Map" }
      ]
    };
  },

  computed: {

    page () {
      return this.$route.path.split(/\/+/)[3];
    },

    tab () {
      return this.tabs.findIndex(t => t.href == this.page);
    },

    ...mapGetters(["adminaccess"])
  },

  methods: {

    tabLink (href) {
      return `/projects/${this.$route.params.project}/${href}`;
    }

  }
}
</script>
