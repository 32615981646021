
function truncateText (text, length=20) {
  if (text?.length <= length) {
    return text;
  } else {
    return text.slice(0, length/2)+"…"+text.slice(-(length/2));
  }
}

export default truncateText;
