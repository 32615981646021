
function user (state) {
  return state.user;
}

function jwt (state) {
  if (state.cookie?.startsWith("JWT=")) {
    return state.cookie.substring(4);
  }
}

function writeaccess (state) {
  return state.user && ["user", "admin"].includes(state.user.role);
}

function adminaccess (state) {
  return state.user && state.user.role == "admin";
}

function preferences (state) {
  return state.preferences;
}

export default { user, jwt, writeaccess, adminaccess, preferences };
