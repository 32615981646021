const { marked, parseInline } = require('marked');

function markdown (str) {
  return marked(String(str));
}

function markdownInline (str) {
  return parseInline(String(str));
}

module.exports = { markdown, markdownInline };
