var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","title":"Configure visible aspects"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-wrench-outline")])],1)]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',[_c('v-list',{attrs:{"nav":"","subheader":""}},[_c('v-subheader',[_vm._v("Visualisations")]),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.aspectsVisible),callback:function ($$v) {_vm.aspectsVisible=$$v},expression:"aspectsVisible"}},[_c('v-list-item',{attrs:{"value":"DougalGraphGunsPressure"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Series: Gun pressure")]),_c('v-list-item-subtitle',[_vm._v("Array pressures weighted averages")])],1)]}}])}),_c('v-list-item',{attrs:{"value":"DougalGraphGunsTiming"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Series: Gun timing")]),_c('v-list-item-subtitle',[_vm._v("Array timing averages")])],1)]}}])}),_c('v-list-item',{attrs:{"value":"DougalGraphGunsDepth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Series: Gun depth")]),_c('v-list-item-subtitle',[_vm._v("Array depths averages")])],1)]}}])}),_c('v-list-item',{attrs:{"value":"DougalGraphGunsHeatmap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Heatmap: Gun parameters")]),_c('v-list-item-subtitle',[_vm._v("Detail of every gun × every shotpoint")])],1)]}}])}),_c('v-list-item',{attrs:{"value":"DougalGraphArraysIJScatter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Series: I/J error")]),_c('v-list-item-subtitle',[_vm._v("Inline / crossline error")])],1)]}}])})],1)],1),_c('v-divider'),_c('v-card-actions',[(_vm.user)?_c('v-btn',{attrs:{"color":"warning","text":"","title":'Save as preference for user '+_vm.user.name+' on this computer (other users may have other defaults).'},on:{"click":_vm.save}},[_vm._v("Save as default")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.open=false}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }