<template>
  <v-card flat elevation="0">
    <v-card-subtitle>Item options</v-card-subtitle>
    <v-card-text>
      <v-select
        label="Value type"
        v-model="type"
        :items="types"
        value="int"
        :readonly="readonly"
      ></v-select>

      <v-checkbox
        label="Enumerated values"
        v-model="enumerated"
        :readonly="readonly"
      ></v-checkbox>
    </v-card-text>

    <template v-if="enumerated">
      <v-card-subtitle>Valid options</v-card-subtitle>
      <v-card-text>
        <v-list dense>
          <v-list-item v-for="(out, key) in value.enum" :key=key
          >
            <v-list-item-content class="mr-1">
              <v-text-field
                dense
                hide-details="auto"
                v-model="key"
                :readonly="readonly"
              ></v-text-field>
            </v-list-item-content>
            <v-list-item-content class="ml-1">
              <v-select v-if="type == 'bool'"
                dense
                hide-details="auto"
                :items="[ true, false ]"
                v-model="value.enum[key]"
                :readonly="readonly"
              ></v-select>
              <v-text-field v-else
                dense
                hide-details="auto"
                v-model="value.enum[key]"
                :readonly="readonly"
              ></v-text-field>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                small
                color="error"
                :disabled="readonly"
                @click="removeEnum(key)"
              >mdi-minus-circle</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item v-if="!readonly"
          >
            <v-list-item-content class="mr-1">
              <v-text-field
                dense
                hide-details="auto"
                label="New input value"
                v-model="newEnumKey"
              ></v-text-field>
            </v-list-item-content>
            <v-list-item-content class="ml-1">
              <v-select v-if="type == 'bool'"
                dense
                hide-details="auto"
                label="New output value"
                :items="[ true, false ]"
                v-model="newEnumValue"
              ></v-select>
              <v-text-field v-else
                dense
                hide-details="auto"
                label="New output value"
                v-model="newEnumValue"
              ></v-text-field>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                small
                color="primary"
                :disabled="!isNewEnumValid"
                @click="addEnum"
                >mdi-plus-circle</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-select v-if="type == 'bool'"
                dense
                hide-details="auto"
                label="Default value"
                hint="Value to use if none matches"
                :items="[ true, false ]"
                v-model="defaultValue"
                :readonly="readonly"
              ></v-select>
              <v-text-field v-else
                label="Default value"
                hint="Value to use if none matches"
                persistent-hint
                v-model="defaultValue"
                :readonly="readonly"
              ></v-text-field>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                small
                color="secondary"
                :disabled="readonly"
                @click="defaultValue = null"
              >mdi-backspace</v-icon>
            </v-list-item-action>
          </v-list-item>

        </v-list>

      </v-card-text>
    </template>
  </v-card>
</template>

<script>

export default {
  name: "DougalFieldContent",

  props: {
    value: Object,
    readonly: Boolean
  },

  data () {
    return {
      newEnumKey: null,
      newEnumValue: null,
      types: [
        { text: "Text", value: "str" },
        { text: "Integer", value: "int" },
        { text: "Float", value: "float" },
        { text: "Boolean", value: "bool" },
      ]
    }
  },

  computed: {

    type: {
      get () {
        return this.value?.type ?? "str";
      },

      set (v) {
          this.$emit("input", {
            ...this.value,
            type: v
          })
      }
    },

    enumerated: {
      get () {
        return typeof this.value?.enum === "object";
      },

      set (v) {
        if (v) {
          this.$emit("input", {
            enum: {},
            ...this.value
          })
        } else {
          const obj = {...this.value};
          delete obj.enum;
          this.$emit("input", obj)
        }
      }
    },

    defaultValue: {

      get () {
        return this.value?.default;
      },

      set (v) {
        this.$emit("input", {
          ...this.value,
          "default": v
        });
      }

    },

    isNewEnumValid () {
      return !!(this.newEnumKey &&
        !Object.keys(this.value.enum).includes(this.newEnumKey) &&
        (typeof this.newEnumValue == "boolean" || this.newEnumValue));
    }

  },

  watch: {
  },

  methods: {

    addEnum () {
      this.$emit("input", {
        ...this.value,
        enum: {
          ...this.value.enum,
          [this.newEnumKey]: this.newEnumValue
        }
      });
      this.newEnumKey = null;
      this.newEnumValue = null;
    },

    removeEnum (key) {
      const obj = {...this.value.enum};
      delete obj[key];
      this.$emit("input", {
        ...this.value,
        enum: obj
      });
    }

  },

  mounted () {
  }

}
</script>
