<template>
  <div>
    <v-combobox
      ref="remarks"
      :value="text"
      @input="handleComboBox"
      :search-input.sync="entryRemarksInput"
      :items="remarksAvailable"
      :filter="searchRemarks"
      item-text="text"
      return-object
      label="Remarks"
      hint="Placeholders: @DMS@, @DEG@, @EN@, @WD@, @BSP@, @CMG@, …"
      prepend-icon="mdi-text-box-outline"
      append-outer-icon="mdi-magnify"
      @click:append-outer="(e) => remarksMenu = e"
    ></v-combobox>

    <dougal-context-menu
      :value="remarksMenu"
      @input="handleRemarksMenu"
      :items="presetRemarks"
      absolute
    ></dougal-context-menu>

    <v-expansion-panels v-if="haveProperties"
      class="px-8"
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>Properties</v-expansion-panel-header>
        <v-expansion-panel-content>
          <dougal-event-properties-edit
            :value="text"
            @input="$emit('update:text', $event)"
            :template="template"
            :schema="schema"
            :values="values"
            @update:values="$emit('update:values', $event)"
          >
          </dougal-event-properties-edit>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DougalContextMenu from '@/components/context-menu';
import DougalEventPropertiesEdit from '@/components/event-properties';

export default {
  name: "DougalEventSelect",

  components: {
    DougalContextMenu,
    DougalEventPropertiesEdit
  },

  props: {
    text: String,
    template: String,
    schema: Object,
    values: Array,
    presetRemarks: Array
  },

  data () {
    return {
      entryRemarksInput: null,
      remarksMenu: false,
    }
  },

  computed: {
    remarksAvailable () {
      return this.entryRemarksInput == this.text
        ? []
        : this.flattenRemarks(this.presetRemarks);
    },

    haveProperties () {
      for (const key in this.schema) {
        return true;
      }
      return false;
    }

  },

  watch: {
  },

  methods: {


    flattenRemarks (items, keywords=[], labels=[]) {
        const result = [];

        if (items) {
          for (const item of items) {
              if (!item.items) {
                  result.push({
                      text: item.text,
                      properties: item.properties,
                      labels: labels.concat(item.labels??[]),
                      keywords
                  })
              } else {
                  const k = [...keywords, item.text];
                  const l = [...labels, ...(item.labels??[])];
                  result.push(...this.flattenRemarks(item.items, k, l))
              }
          }
        }
        return result;
    },

    searchRemarks (item, queryText, itemText) {
      const needle = queryText.toLowerCase();
      const text = item.text.toLowerCase();
      const keywords = item.keywords.map(i => i.toLowerCase());
      const labels = item.labels.map(i => i.toLowerCase());
      return text.includes(needle) ||
        keywords.some(i => i.includes(needle)) ||
        labels.some(i => i.includes(needle));
    },

    handleComboBox (event) {
      if (typeof event == "object") {
        this.$emit("update:text", event.text);
        this.$emit("update:template", event.template ?? event.text);
        this.$emit("update:schema", event.properties);
        this.$emit("update:labels", event.labels);
      } else {
        this.$emit("update:text", event);
        this.$emit("update:template", null);
        this.$emit("update:properties", null);
        this.$emit("update:labels", []);
      }
    },

    handleRemarksMenu (event) {
      if (typeof event == 'boolean') {
        this.remarksMenu = event;
      } else {
        this.$emit("update:text", event.text);
        this.$emit("update:template", event.template ?? event.text);
        this.$emit("update:schema", event.properties);
        this.$emit("update:labels", event.labels);
        this.remarksMenu = false;
      }
    },
  },

  mount () {
    // Focus remarks field
    this.$nextTick(() => this.$refs.remarks.focus());
  }
}

</script>
