<template>
  <v-dialog
    max-width="600"
    v-model="open"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        :title="title"
      >mdi-folder-network-outline</v-icon>
    </template>
    <v-card>
      <v-card-title>File picker</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <dougal-file-browser
          v-model="selected"
          :mimetypes="mimetypes"
          :root="root"
          ref="browser"
        >
        </dougal-file-browser>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="save" :disabled="!selected">
          <v-icon small flat color="primary" class="mr-2">mdi-content-save</v-icon>
          Ok
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="refresh">
          <v-icon small flat class="mr-2">mdi-reload</v-icon>
          Refresh
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          <v-icon small flat color="red" class="mr-2">mdi-close</v-icon>
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DougalFileBrowser from './file-browser';

export default {
  name: "DougalFileBrowserDialog",

  components: { DougalFileBrowser },

  props: [ "path", "mimetypes", "root", "title" ],

  data () {
    return {
      open: false,
      selected: ""
    }
  },

  methods: {

    refresh () {
      this.$refs.browser.refresh();
    },

    close () {
      this.open = false;
    },

    save () {
      this.$emit('input', this.selected);
      this.close();
    }

  },

  mounted () {
    this.selected = this.path;
  }
}
</script>
