var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","clipped-left":"","elevation":"1"},scopedSlots:_vm._u([(_vm.appBarExtension)?{key:"extension",fn:function(){return [_c(_vm.appBarExtension,{tag:"div"})]},proxy:true}:null],null,true)},[_c('v-img',{attrs:{"src":"/wgp-logo.png","contain":"","max-height":"32px","max-width":"32px"}}),_c('v-toolbar-title',{staticClass:"mx-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("Dougal")]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"xcolor":hover ? 'secondary' : 'secondary lighten-3',"small":"","text":"","title":"Settings"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cog-outline")])],1)]}}],null,true)})]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"href":"/settings/equipment"}},[_c('v-list-item-title',[_vm._v("Equipment list")]),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-view-list")])],1)],1)],1)],1),_c('v-breadcrumbs',{attrs:{"items":_vm.path}}),(_vm.$route.name != 'Login')?[(!_vm.user && !_vm.loading)?_c('v-btn',{attrs:{"text":"","link":"","to":"/login"}},[_vm._v("Log in")]):(_vm.user)?[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":_vm.user.colour || 'primary',"title":((_vm.user.name) + " (" + (_vm.user.role) + ")")}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.user.name.slice(0, 5)))])])]}}],null,false,3788583871)},[_c('v-list',{attrs:{"dense":""}},[(_vm.user.autologin)?_c('v-list-item',{attrs:{"link":"","to":"/login"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-login")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Log in as a different user")]),_c('v-list-item-subtitle',[_vm._v("Autologin from "+_vm._s(_vm.user.ip))])],1)],1):_c('v-list-item',{attrs:{"link":"","to":"/logout"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-logout")])],1),_c('v-list-item-title',[_vm._v("Log out")])],1)],1)],1)]:_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }