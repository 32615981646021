<template>
  <v-card>
    <v-card-title>Files</v-card-title>
    <v-card-subtitle>File path configuration for this project.</v-card-subtitle>
    <v-card-text>
      <v-form>
        <v-text-field
          label="Project folder"
          hint="Root file path for this project"
          persistent-hint
          v-model="cwo.rootPath"
        >
          <dougal-file-browser-dialog
            slot="append"
            v-model="cwo.rootPath"
            mimetypes="inode/directory"
          ></dougal-file-browser-dialog>
        </v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!--
      <v-btn
        color="primary"
        @click="save"
      >Save</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="warning"
        @click="reset"
      >Reset</v-btn>
      -->
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="back"
      >Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DougalFileBrowserDialog from '@/components/file-browser/file-browser-dialog';

export default {
  name: "DougalProjectSettingsFilePath",

  components: { DougalFileBrowserDialog },

  props: {
    value: Object
  },

  data () {
    return {
    }
  },

  computed: {
    // Current working object.
    // A shortcut so we don't have to specify the full path
    // on every input control. It also makes it easier to
    // change that path if necessary. Finally, it ensures that
    // the properties being modified are always available.
    cwo: {

      get () {
        if (this.value) {
          return this.value;
        } else {
          return {};
        }
      },

      set (v) {
        this.value = v;
      }

    }
  },

  methods: {

    reset () {
    },

    save () {
    },

    back () {
      this.$emit('close');
    }

  },

  mounted () {
    this.reset();
  }

}
</script>
