<template>
  <v-card>
    <v-card-title>Production settings</v-card-title>
    <v-card-subtitle></v-card-subtitle>
    <v-card-text>
      <v-form>
        <v-text-field
          label="Nominal line change duration"
          hint="Duration of the nominal elapsed time circling between lines, in minutes. If this time is exceeded between one sequence and the next, an entry will be made in the events log"
          persistent-hint
          suffix="min"
          type="number"
          v-model.number="cwo.nominalLineChangeDuration"
        >
        </v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!--
      <v-btn
        color="primary"
        @click="save"
      >Save</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="warning"
        @click="reset"
      >Reset</v-btn>
      -->
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="back"
      >Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { deepSet } from '@/lib/utils';

export default {
  name: "DougalProjectSettingsProduction",

  props: {
    value: Object
  },

  data () {
    return {
    }
  },

  computed: {
    // Current working object.
    // A shortcut so we don't have to specify the full path
    // on every input control. It also makes it easier to
    // change that path if necessary. Finally, it ensures that
    // the properties being modified are always available.
    cwo: {

      get () {
        if (this.value) {
          if (!this.value?.production) {
            deepSet(this.value, [ "production" ], {
              nominalLineChangeDuration: null
            });
          }
          return this.value.production;
        } else {
          return {};
        }
      },

      set (v) {
        if (this.value) {
          deepSet(this.value, [ "production" ], v);
        }
      }

    }
  },

  methods: {

    reset () {
    },

    save () {
    },

    back () {
      this.$emit('close');
    }

  },

  mounted () {
    this.reset();
  }

}
</script>
