<template>
<v-dialog
  v-model="dialog"
  max-width="500"
  style="z-index:2020;"
>
  <template v-slot:activator="{ on, attrs }">
	<small class="ml-3">
      <a v-on="on">
        <span class="d-none d-sm-inline">Get help </span>
        <v-icon small>mdi-account-question</v-icon>
      </a>
	</small>
  </template>

  <v-card>
    <v-card-title class="headline">
      Dougal user support
    </v-card-title>

    <v-card-text>
      <p>You can get help or report a problem by sending an email to <a :href="`mailto:${email}`">{{email}}</a>. Please include as much information as possible about your problem or question—screenshots are often a good idea, and data files may also be attached.</p>

      <p>When you write to the above address a ticket will be automatically created in the project's issue tracking system.</p>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>

      <v-btn
        color="info"
        text
        :href="`mailto:${email}?Subject=Question`"
      >
        <v-icon class="d-lg-none">mdi-help-circle</v-icon>
        <span class="d-none d-lg-inline">Ask a question</span>
      </v-btn>

      <v-btn
        color="warning darken-1"
        text
        href="mailto:dougal-support@aaltronav.eu?Subject=Bug report"
      >
        <v-icon class="d-lg-none">mdi-bug</v-icon>
        <span class="d-none d-lg-inline">Report a bug</span>
      </v-btn>

      <v-btn
        color="info"
        text
        :href='"/feed/"+feed'
        title="View development log"
      >
        <v-icon>mdi-rss</v-icon>
      </v-btn>

      <v-spacer></v-spacer>


      <v-btn
        color="primary darken-1"
        text
        @click="dialog=false"
      >
        <v-icon class="d-lg-none">mdi-close-circle</v-icon>
        <span class="d-none d-lg-inline">Close</span>
      </v-btn>
    </v-card-actions>

  </v-card>

</v-dialog>

</template>

<script>
export default {
  name: 'DougalHelpDialog',

  data () {
    return {
      dialog: false,
      email: "dougal-support@aaltronav.eu",
      feed: btoa(encodeURIComponent("https://gitlab.com/wgp/dougal/software.atom?feed_token=XSPpvsYEny8YmH75Nz5W"))
    };
  }

};
</script>
