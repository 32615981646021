/** Unpacks attributes from array items.
 *
 * At it simplest, given an array of objects,
 * the call unpack(rows, "x") returns an array
 * of the "x" attribute of every item in rows.
 *
 * `key` may also be:
 *
 * - a function with the signature
 *   (Object) => any
 *   the result of applying the function to
 *   the object will be used as the unpacked
 *   value.
 *
 * - an array of strings, functions or other
 *   arrays. In this case, it does a recursive
 *   fold operation. NOTE: it mutates `key`.
 *
 */
export default function unpack(rows, key) {
  if (typeof key === "function") {
    return rows && rows.map( row => key(row) );
  } else if (Array.isArray(key)) {
    const car = key.shift();
    if (key.length) {
      return unpack(unpack(rows, car), key);
    } else {
      return unpack(rows, car);
    }
  } else {
    return rows && rows.map( row => row?.[key] );
  }
};
