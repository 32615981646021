<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="mx-auto" max-width="600" tile>
          <v-card-title style="word-break: normal;">Dougal – Seismic Production &amp; Data Analysis Tool</v-card-title>
          <v-card-text>
            <v-list two-line subheader>
              <v-subheader>No recent projects</v-subheader>
              <!--
              <v-subheader>Recent projects</v-subheader>
              <v-list-item-group>
                <v-list-item link to="/projects/eq20211">
                  <v-list-item-content>
                    <v-list-item-title>EQ20211</v-list-item-title>
                    <v-list-item-subtitle>Snorre PRM</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              -->
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" to="/projects/">All projects</v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn text color="success">New project</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
