<template>

  <v-app-bar
    app
    clipped-left
    elevation="1"
  >
    <v-img src="/wgp-logo.png"
      contain
      max-height="32px" max-width="32px"
    ></v-img>

    <v-toolbar-title class="mx-2" @click="$router.push('/')" style="cursor: pointer;">Dougal</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu bottom offset-y>
      <template v-slot:activator="{on, attrs}">
        <v-hover v-slot="{hover}">
          <v-btn
            class="align-self-center"
            :xcolor="hover ? 'secondary' : 'secondary lighten-3'"
            small
            text
            v-bind="attrs"
            v-on="on"
            title="Settings"
          >
            <v-icon small>mdi-cog-outline</v-icon>
          </v-btn>
        </v-hover>
      </template>

      <v-list dense>
        <v-list-item :href="`/settings/equipment`">
          <v-list-item-title>Equipment list</v-list-item-title>
          <v-list-item-action><v-icon small>mdi-view-list</v-icon></v-list-item-action>
        </v-list-item>
      </v-list>

    </v-menu>


    <v-breadcrumbs :items="path"></v-breadcrumbs>

    <template v-if="$route.name != 'Login'">
      <v-btn text link to="/login" v-if="!user && !loading">Log in</v-btn>
      <template  v-else-if="user">

        <v-menu
          offset-y
        >
          <template v-slot:activator="{on, attrs}">
            <v-avatar :color="user.colour || 'primary'" :title="`${user.name} (${user.role})`" v-bind="attrs" v-on="on">
              <span class="white--text">{{user.name.slice(0, 5)}}</span>
            </v-avatar>
          </template>

          <v-list dense>
            <v-list-item link to="/login" v-if="user.autologin">
              <v-list-item-icon><v-icon small>mdi-login</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Log in as a different user</v-list-item-title>
                <v-list-item-subtitle>Autologin from {{user.ip}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/logout" v-else>
              <v-list-item-icon><v-icon small>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-title>Log out</v-list-item-title>
            </v-list-item>
          </v-list>

        </v-menu>

      </template>
    </template>
    <template v-slot:extension v-if="appBarExtension">
      <div :is="appBarExtension"></div>
    </template>
  </v-app-bar>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DougalNavigation',
  data() {
    return {
      drawer: false,
      path: []
    };
  },

  computed: {

    appBarExtension () {
      return this.$route.matched
        .filter(i => i.meta?.appBarExtension)
        .map(i => i.meta.appBarExtension)
        .pop()?.component;
    },

    ...mapGetters(['user', 'loading'])
  },

  watch: {
    "$route" (newVal, oldVal) {
      if (newVal.matched != oldVal.matched) {
        this.breadcrumbs();
      }
    },

    "$store.state.project.projectId" () {
      this.breadcrumbs();
    }
  },

  methods: {

    breadcrumbs () {
      this.path = this.$route.matched
        .map(i => {
          return {
            breadcrumbs: i.meta && i.meta.breadcrumbs,
            ctx: i.instances.default
          }
        })
        .filter(i => i.breadcrumbs)
        .flat()
        .map(i => {

          return i.breadcrumbs.map( breadcrumb => {
            const o = {};
            for (const key of Object.keys(breadcrumb)) {
              o[key] = typeof breadcrumb[key] === 'function'
                ? i.ctx && breadcrumb[key](i.ctx)
                : breadcrumb[key];
            }
            return o;
          });
        })
        .flat();
    }
  },

  mounted () {
    this.breadcrumbs();
  }

};
</script>
