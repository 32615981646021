
/** Fetch sequences from server
 */
async function refreshSequences ({commit, dispatch, state, rootState}) {

  if (state.loading) {
    commit('abortSequencesLoading');
  }

  commit('setSequencesLoading');
  const pid = rootState.project.projectId;
  const url = `/project/${pid}/sequence?files=true`;
  const init = {
    signal: state.loading.signal
  };
  const res = await dispatch('api', [url, init]);

  if (res) {
    commit('setSequences', res);
    commit('setSequencesTimestamp');
  }
  commit('clearSequencesLoading');
}

/** Return a subset of sequences from state.sequences
 */
async function getSequences ({commit, dispatch, state}, [projectId, {sequence, date0, date1, sortBy, sortDesc, itemsPerPage, page, text}]) {
  let filteredSequences = [...state.sequences];

  if (sortBy) {

    sortBy.forEach( (key, idx) => {
      filteredSequences.sort( (el0, el1) => {
        const a = el0?.[key];
        const b = el1?.[key];
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else if (a == b) {
          return 0;
        } else if (a && !b) {
          return 1;
        } else if (!a && b) {
          return -1;
        } else {
          return 0;
        }
      });
      if (sortDesc && sortDesc[idx] === true) {
        filteredSequences.reverse();
      }
    });

  }

  if (sequence) {
    filteredSequences = filteredSequences.filter( sequence => sequence.sequence == sequence );
  }

  if (date0 && date1) {
    filteredSequences = filteredSequences.filter( sequence =>
      (sequence.ts0_final ?? sequence.ts0)?.substr(0, 10) >= date0 &&
      (sequence.ts1_final ?? sequence.ts1)?.substr(0, 10) <= date1
    );
  } else if (date0) {
    filteredSequences = filteredSequences.filter( sequence => (sequence.ts0_final ?? sequence.ts0)?.substr(0, 10) == date0 || (sequence.ts1_final ?? sequence.ts1)?.substr(0, 10) );
  }

  if (text) {
    const tstampFilter = (value, search, item) => {
      return search?.length >= 5 && textFilter(value, search, item);
    };

    const numberFilter = (value, search, item) => {
      return value == search;
    };

    const textFilter = (value, search, item) => {
      return String(value).toLowerCase().includes(search.toLowerCase());
    };

    const searchFunctions = {
      ts0: tstampFilter,
      ts1: tstampFilter,
      ts0_final: tstampFilter,
      ts1_final: tstampFilter,
      sequence: numberFilter,
      line: numberFilter,
      fsp: numberFilter,
      lsp: numberFilter,
      fsp_final: numberFilter,
      fsp_final: numberFilter,
      remarks: textFilter,
      remarks_final: textFilter
    };

    filteredSequences = filteredSequences.filter ( sequence => {
      for (let key in searchFunctions) {
        const fn = searchFunctions[key];
        if (fn(sequence[key], text, sequence)) {
          return true;
        }
      }
      return false;
    });
  }

  const count = filteredSequences.length;

  if (itemsPerPage && itemsPerPage > 0) {
    const offset = (page > 0)
      ? (page-1) * itemsPerPage
      : 0;

    filteredSequences = filteredSequences.slice(offset, offset+itemsPerPage);
  }

  return {sequences: filteredSequences, count};
}

export default { refreshSequences, getSequences };
