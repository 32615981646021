

function transform (item) {
  item.ts0 = new Date(item.ts0);
  item.ts1 = new Date(item.ts1);
  return item;
}

// ATTENTION: This relies on the new planner endpoint
// as per issue #281.

function setPlan (state, plan) {
  // We don't need or want the planned sequences array to be reactive
  state.sequences = Object.freeze(plan.sequences.map(transform));
  state.remarks = plan.remarks;
}

function setPlanLoading (state, abortController = new AbortController()) {
  state.loading = abortController;
}

// This assumes that we know any transactions have finished or we
// don't care about aborting.
function clearPlanLoading (state) {
  state.loading = null;
}

function setPlanTimestamp (state, timestamp = new Date()) {
  // NOTE: There is no `modified_on` property in the plan
  // result or in the database schema, but we should probably add
  // one.
  if (timestamp === true) {
    const tstamp = state.plan
      .map( item => item.modified_on )
      .reduce( (acc, cur) => acc > cur ? acc : cur );
    state.timestamp = tstamp ? new Date(tstamp) : new Date();
  } else {
    state.timestamp = timestamp;
  }
}

function setPlanETag (state, etag) {
  state.etag = etag;
}

function abortPlanLoading (state) {
  if (state.loading) {
    state.loading.abort();
  }
  state.loading = null;
}

export default {
  setPlan,
  setPlanLoading,
  clearPlanLoading,
  setPlanTimestamp,
  setPlanETag
};
