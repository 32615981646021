
/** Fetch sequences from server
 */
async function refreshPlan ({commit, dispatch, state, rootState}) {

  if (state.loading) {
    commit('abortPlanLoading');
  }

  commit('setPlanLoading');
  const pid = rootState.project.projectId;
  const url = `/project/${pid}/plan`;
  const init = {
    signal: state.loading.signal
  };
  const res = await dispatch('api', [url, init]);

  if (res) {
    commit('setPlan', res);
    commit('setPlanTimestamp');
  }
  commit('clearPlanLoading');
}

/** Return a subset of sequences from state.sequences
 */
async function getPlannedSequences ({commit, dispatch, state}, [projectId, {sequence, date0, date1, sortBy, sortDesc, itemsPerPage, page, text}]) {
  let filteredPlannedSequences = [...state.sequences];

  if (sortBy) {

    sortBy.forEach( (key, idx) => {
      filteredPlannedSequences.sort( (el0, el1) => {
        const a = el0?.[key];
        const b = el1?.[key];
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else if (a == b) {
          return 0;
        } else if (a && !b) {
          return 1;
        } else if (!a && b) {
          return -1;
        } else {
          return 0;
        }
      });
      if (sortDesc && sortDesc[idx] === true) {
        filteredPlannedSequences.reverse();
      }
    });

  }

  if (sequence) {
    filteredPlannedSequences = filteredPlannedSequences.filter( sequence => sequence.sequence == sequence );
  }

  if (date0 && date1) {
    filteredPlannedSequences = filteredPlannedSequences.filter( sequence =>
      sequence.ts0.substr(0, 10) >= date0 && sequence.ts1.substr(0, 10) <= date1
    );
  } else if (date0) {
    filteredPlannedSequences = filteredPlannedSequences.filter( sequence => sequence.ts0.substr(0, 10) == date0 || sequence.ts1.substr(0, 10) );
  }

  if (text) {
    const tstampFilter = (value, search, item) => {
      return textFilter(value.toISOString(), search, item);
    };

    const numberFilter = (value, search, item) => {
      return value == search;
    };

    const textFilter = (value, search, item) => {
      return String(value).toLowerCase().includes(search.toLowerCase());
    };

    const searchFunctions = {
      sequence: numberFilter,
      line: numberFilter,
      remarks: textFilter,
      ts0: tstampFilter,
      ts1: tstampFilter
    };

    filteredPlannedSequences = filteredPlannedSequences.filter ( sequence => {
      for (let key in searchFunctions) {
        const fn = searchFunctions[key];
        if (fn(sequence[key], text, sequence)) {
          return true;
        }
      }
      return false;
    });
  }

  const count = filteredPlannedSequences.length;

  if (itemsPerPage && itemsPerPage > 0) {
    const offset = (page > 0)
      ? (page-1) * itemsPerPage
      : 0;

    filteredPlannedSequences = filteredPlannedSequences.slice(offset, offset+itemsPerPage);
  }

  return {sequences: filteredPlannedSequences, count};
}

export default { refreshPlan, getPlannedSequences };
